<template>
  <div class="create-allocation">
    <b-row class="mb-10">
      <b-col>
        <span class="main-heading">Create Allocation</span>
        <br />
        <span class="sub-heading">for {{ clientName }}</span>
      </b-col>
      <b-col
        cols="auto"
        role="button"
        @click="$bvModal.hide('create-allocation-modal')"
        class="close"
      >
        close
      </b-col>
    </b-row>

    <form ref="form" class="form-data">
      <!-- ADD DATE -->
      <b-form-group id="fieldset" label="Add Date" label-for="add-date">
        <b-row id="add-date">
          <b-col class="pb-0 pt-0">
            <b-form-group
              id="fieldset-start-date"
              label-cols-sm="4"
              label-cols-lg="2"
              content-cols-sm
              content-cols-lg="10"
              label="from"
              label-for="start-date"
            >
              <b-form-datepicker
                id="start-date"
                v-model="startDate"
                @context="onSelectStartDate"
                placeholder="1/10/2020"
                locale="en"
                :max="endDate"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>

          <b-col class="pb-0 pt-0">
            <b-form-group
              id="fieldset-end-date"
              label-cols-sm="4"
              label-cols-lg="2"
              content-cols-sm
              content-cols-lg="10"
              label="to"
              label-for="end-date"
            >
              <b-form-datepicker
                id="end-date"
                v-model="endDate"
                @context="onSelectEndDate"
                placeholder="31/10/2020"
                locale="en"
                :min="startDate"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="pt-0">
            <span class="days-info">(31 DAYS)</span>
          </b-col>
        </b-row>
      </b-form-group>

      <!-- ADD BUDGET AND ADD TARGET-->
      <b-row>
        <b-col class="col-12 col-md-6">
          <b-form-group
            id="budget-fieldset"
            class="input-label"
            label="Add Budget"
            label-for="budget"
          >
            <b-input-group class="mt-3">
              <template #append>
                <div class="range-wrapper">
                  <b-button @click="budgetPercentage--">-</b-button>
                  <span>{{ budgetPercentage }}%</span>
                  <b-button @click="budgetPercentage++">+</b-button>
                </div>
              </template>
              <b-form-input
                id="budget"
                v-model="budget"
                class="input-field custom-input"
                type="text"
                required
                placeholder="AED 200"
              />
            </b-input-group>

            <span class="input-info"> AED 200 remaining </span>
          </b-form-group>
        </b-col>

        <b-col class="col-12 col-md-6">
          <b-form-group
            id="target-fieldset"
            class="input-label"
            label="Add Target"
            label-for="target"
          >
            <b-input-group class="mt-3">
              <template #append>
                <div class="range-wrapper">
                  <b-button @click="targetPercentage--">-</b-button>
                  <span>{{ targetPercentage }}%</span>
                  <b-button @click="targetPercentage++">+</b-button>
                </div>
              </template>
              <b-form-input
                id="target"
                v-model="target"
                class="input-field custom-input"
                type="text"
                required
                placeholder="AED 8,000"
              />
            </b-input-group>
            <span class="input-info"> AED 4,000 remaining </span>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- ADD BUTTON -->
      <b-form-group class="mt-15">
        <b-button
          :disabled="!isFormValidated || isLoading"
          class="add-btn"
          @click="handleSubmit"
          >Add <b-spinner v-if="isLoading" small type="grow"></b-spinner>
        </b-button>
      </b-form-group>
    </form>
  </div>
</template>
<style lang="scss">
.create-allocation {
  .main-heading {
    font-weight: bold;
    font-size: 29px;
    color: #000000;
  }
  .sub-heading {
    font-size: 20px;
    font-weight: 600;
    color: #939393;
  }
  .close {
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    opacity: 0.36;
  }
  .form-data {
    margin-top: 30px;
    .b-form-datepicker {
      background: #f3fcff 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
      height: 50px;
      font-size: 18px;
    }
    input {
      height: 53px;
      background: #f3fcff 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
      font-size: 18px;
      color: #000000;
    }
    label {
      align-self: center;
      font-size: 20px;
      color: #000000;
    }
    .add-btn {
      float: right;
      width: 229px;
      height: 53px;
      background: #1374d6 0% 0% no-repeat padding-box;
      border-radius: 10px;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 1.6px;
      color: #ffffff;
    }
    .input-info {
      color: #000000;
      font-size: 14px;
      line-height: 30px;
    }
    .range-wrapper {
      background: #000000 0% 0% no-repeat padding-box;
      border-radius: 0px 8px 8px 0px;
      color: #f3fcff;
      font-size: 20px;
      font-weight: bold;
      margin-left: 1px;
      button,
      button:hover,
      button:active,
      button:focus {
        background: black !important;
        border: none;
        color: #fff;
        font-size: 19px;
        font-weight: bold;
        height: 100%;
      }
    }
    .days-info {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0px;
      color: #f76d67;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import ApiService from "../../../../core/services/api.service";
import Button from "../../vue-bootstrap/Button.vue";
export default {
  name: "create-allocation",
  props: ["ClientName"],
  components: { Button },
  data: () => ({
    startDate: "",
    endDate: "",
    startCompleteDate: null,
    endCompleteDate: null,
    budget: "",
    target: "",
    isLoading: false,
    budgetPercentage: 12,
    targetPercentage: 21,
  }),
  methods: {
    /* event triggered on selecting start date */
    onSelectStartDate(ctx) {
      console.log("start date select", ctx);
      this.startCompleteDate = ctx.activeDate;
    },

    /* event triggered on selecting end date */
    onSelectEndDate(ctx) {
      console.log("end date select", ctx);
      this.endCompleteDate = ctx.activeDate;
    },

    handleSubmit() {
      let dataToPost = {
        title: "1-7 March 2021",
        budget: this.budget,
        budget_currency: "AED",
        target: this.target,
        target_sales_currency: "AED",
        is_draft: false,
        client_id: this.$route.params.id,
        campaign_id: this.selectedCampaign[0]._id,
      };
      this.isLoading = true;
      console.log("parent of create allocation", this.$allocation);
      this.$bvModal.hide("create-allocation-modal");
      // return
      ApiService.post("/allocations", dataToPost).then((response) => {
        this.isLoading = false;
      });
    },
  },
  computed: {
    ...mapGetters(["CampaignGetters"]),
    selectedCampaign() {
      return this.$store.getters.CampaignGetters;
    },
    isFormValidated() {
      if (this.startDate && this.endDate && this.budget && this.target) {
        return true;
      }
      return false;
    },
  },
};
</script>
