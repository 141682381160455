<template>
  <div>
    <!-- Edit Modal Component -->
    <edit-allocation :row="edit_row" v-if="edit_row" />
    <ag-grid-vue
      class="ag-grid-table ag-theme-material table table-responsive"
      :context="context"
      :gridOptions="gridOptions"
      :suppressDragLeaveHidesColumns="true"
      @grid-ready="onGridReady"
      :defaultColDef="defaultColDef"
      :rowData="allocation"
      :animateRows="true"
      rowSelection="single"
      @rowSelected="onSelectCampaign"
    >
    </ag-grid-vue>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ApiService from "../../core/services/api.service";
import { AgGridVue } from "ag-grid-vue";
import CellRendererDropdown from "@/view/pages/CellRenderDropdown.vue";
import CellRendererAction from "@/view/pages/CellRenderAction.vue";
import editAllocation from "../pages/performance_management/allocations/Edit_Allocation";
import editCampaign from "../pages/performance_management/campaigns/Edit_Campaign.vue";

export default {
  name: "allocation",
  components: {
    AgGridVue,
    CellRendererDropdown,
    CellRendererAction,
    editAllocation,
    editCampaign,
  },
  data() {
    return {
      context: {},
      editable_row: null,
      selected_camp: this.$store.getters.InfCampaignGetters,
      searchQuery: "",
      maxPageNumbers: 7,
      gridApi: null,
      StartingPlan: false,
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        flex: 1,
        minWidth: 120,
        filter: true,
        resizable: true,
      },
      gridOptions: {
        frameworkComponents: {
          //   CellRendererAvatars: CellRendererAvatars,
          //   CellRendererName: CellRendererName,
          //   CellRendererMATERIALS: CellRendererMATERIALS,
          //   CellRendererCoupon: CellRendererCoupon,
          //   CellRendererDropdown: CellRendererDropdown,
          //   CellRendererAction: CellRendererAction
        },
        rowHeight: 60,
        columnDefs: [
          {
            maxWidth: 50,
            checkboxSelection: true,
            headerCheckboxSelection: true,
          },
          {
            headerName: "PERIOD",
            field: "created_at",
            filter: true,
          },
          {
            headerName: "BUDGET",
            field: "budget",
            filter: false,
            valueGetter(params) {
              return `${params.data.budget} ${params.data.budget_currency}`;
            },
          },
          {
            headerName: "TARGET",
            field: "target",
            filter: false,
            valueGetter(params) {
              return `${params.data.target} ${params.data.target_sales_currency}`;
            },
          },
          {
            headerName: "ACTUAL COST",
            field: "budget",
            filter: false,
          },
          {
            headerName: "COMMISSION",
            field: "target",
            filter: false,
          },
          {
            headerName: "PROFIT",
            field: "target",
            filter: false,
          },
          {
            headerName: "STATUS",
            Width: 80,
            cellRendererFramework: "CellRendererDropdown",
            suppressSorting: true,
          },
          {
            headerName: "ACTION",
            Width: 80,
            cellRendererFramework: "CellRendererAction",
            suppressSorting: true,
          },
        ],
      },
      allocation: [],
    };
  },
  computed: {
    //   ...mapGetters(['CampaignGetters']),
    // selectedCampaign(){
    //     return this.$store.getters.CampaignGetters;
    //   },
    edit_row: {
      get() {
        return this.editable_row;
      },
      set() {
        return this.editable_row;
      },
    },
  },
  beforeMount() {
    this.context = {
      parent: this,
      viewable: false,
      editable: true,
      deleteable: true,
    };
  },
  updated() {
    // console.log("Update Cycle of allocation ")
    // this.fetchData();
  },
  methods: {
    onGridReady(params) {
      this.fetchData();
    },
    fetchData() {
      let dataToPost = {
        client_id: this.$route.params.id,
        campaign_id: this.selected_camp[0]._id,
      };
      ApiService.post("/allocations/list", dataToPost).then((response) => {
        this.allocation = response.data.data;
      });
    },
    editGridAction(data) {
      console.log("edit allocation");
      this.editable_row = data;
      this.$bvModal.show("edit-allocation-modal");
      this.fetchData();
    },
    deleteGridAction() {
      console.log("delete allocation");
    },
    onSelectCampaign(campaign) {
      // if (!campaign.node.selected) {
      //   this.selectedCampaign = null;
      //   return;
      // }
      // this.selectedCampaign = this.campaigns.find(
      //   (x) => x.id === campaign.rowIndex
      // );
    },
  },
};
</script>
<style scoped></style>
