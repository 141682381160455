<template>
  <div>
    <div class="row">
      <div class="col-8">
        <b-row>
          <b-col class="col-auto ">
            <img
              class="logo"
              :src="
                client_detail.logo
                
              "
            />
          </b-col>

          <b-col class="col-10 pl-0">
            <h1 class="main-header">
              <b>{{ client_detail.name }}</b>
            </h1>
            <br />
            <span class="badge badge-pill badge-success">Influencer</span>
          </b-col>
        </b-row>
      </div>

      <div class="col-4">
        <div>
          <div class="ml-auto text-right">
            <div style="width: fit-content" class="d-flex ml-auto text-right">
              <v-menu
                ref="dialog"
                v-model="menu"
                offset-y
                :close-on-content-click="true"
                :value="calenderDate"
                transition="scale-transition"
                width="fit-content"
                color="#F64E70"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="dropdown-heres" v-bind="attrs" v-on="on">
                    {{ calenderDate | moment("MMMM") }}
                    <span>
                      <v-icon color="#F64E70"> mdi-menu-down </v-icon>
                    </span>
                  </div>
                </template>
                <v-date-picker
                  color="#F64E70"
                  v-model="calenderDate"
                  type="month"
                  no-title
                  @change="monthFilter(true)"
                >
                </v-date-picker>
              </v-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row plan-boxes mb-5">
      <div class="col-xl-3">
        <div class="plan-box">
          <div class="content-box">
            <h1>{{ campaignCount.total_campaign }}</h1>
            <h4 style="font-weight: 900 !important; color: black">
              No. of Campaigns
            </h4>
          </div>
        </div>
      </div>
      <div class="col-xl-3">
        <div class="plan-box">
          <div class="content-box">
            <span>AED</span>
            <h1>{{ campaignCount.total_cost }}</h1>

            <h4 style="font-weight: 900 !important; color: black">
              Total Budget
            </h4>
          </div>
        </div>
      </div>
      <div class="col-xl-3">
        <div class="plan-box">
          <div class="content-box">
            <span>AED</span>
            <h1>{{ campaignCount.total_profit }}</h1>
            <h4 style="font-weight: 900 !important; color: black">
              Total Profit
            </h4>
          </div>
        </div>
      </div>
      <div class="col-xl-3">
        <div class="plan-box">
          <div class="content-box">
            <span>&nbsp;</span>
            <span>AED</span>
            <!-- <span> {{campaignCount.total_budget_remaining_currency}} </span> -->
            <h1>{{ campaignCount.total_billable }}</h1>
            <h4 style="font-weight: 900 !important; color: black">
              Total Billable Amount
            </h4>
          </div>
        </div>
      </div>
    </div>
    <b-row>
      <b-col cols="auto" v-if="!show_calendar">
        <button
          class="section-btn"
          :class="currentSection === 'campaign' ? 'active' : ''"
          @click="setCurrentSelection('campaign')"
        >
          Campaign
          <span class="selected-info">{{ adsCount }} Selected X</span>
        </button>
      </b-col>
      <!-- <b-col cols="auto" v-if="!show_calendar">
        <button
          class="section-btn"
          :class="currentSection === 'allocation' ? 'active' : ''"
          @click="setCurrentSelection('allocation')"
          :disabled="!this.influencers.length > 0"
        >
          Allocation
          <span v-if="influencers" class="selected-info"
            >for
            {{ this.influencers ? this.influencers.length : 0 }}
            campaign</span
          >
        </button>
      </b-col> -->
      <b-col cols="auto" v-if="!show_calendar">
        <button
          class="section-btn"
          :class="currentSection === 'ads' ? 'active' : ''"
          @click="setCurrentSelection('ads')"
        >
          Ads
          <span class="selected-info">for {{ adsCount }} campaign</span>
        </button>
      </b-col>
      <b-col cols="auto" align-self="center" v-if="!show_calendar">
        <v-btn
          @click="openModal"
          class="mx-1 ml-4 mr-4 mdi-plus-bg"
          fab
          dark
          color="gainsboro"
          style="height: 42px; width: 42px"
        >
          <v-icon class="mdi-plus-content">mdi-plus</v-icon>
        </v-btn>
      </b-col>
      <b-col class="text-right" align-self="end">
        <label for="check-button"
          ><h6 style="padding-top: 3px">
            <b>Switch to Calendar view</b>
          </h6></label
        >
        <b-form-checkbox
          name="check-button"
          size="lg"
          switch
          v-model="show_calendar"
          class="switch-to-calendar-button"
        >
        </b-form-checkbox>
      </b-col>
    </b-row>
    <div class="row mb-10">
      <div class="col-xl-12">
        <div class="infTable">
          <div style="width: 100%" v-if="!show_calendar">
            <div v-if="currentSection == 'campaign'">
              <campaign
                :currentSelect="currentSection"
                :ClientName="client_detail.name"
                :campData="changedValue"
                @clicked="clicked"
                @counts="counts"
                ref="campaignList"
              />
            </div>
            <!-- <div v-else-if="currentSection == 'allocation'">
              <allocation />
            </div> -->
            <div v-else-if="currentSection == 'ads'">
              <!-- <ads
                @changeTitle="ChangeT($event)"
                :camp="selectedCampaignVal"
                :isInfluencer="true"
              /> -->
              <campaign
                :currentSelect="currentSection"
                :ClientName="client_detail.name"
                :campData="changedValue"
                @clicked="clicked"
                ref="campaignList"
              />
            </div>
            <div v-else>
              <h1><b>No campaigns created yet</b></h1>
              <h3>
                <b
                  >click the
                  <v-btn
                    class="mx-1 ml-4 mr-4 mdi-plus-bg2"
                    fab
                    dark
                    color="blue"
                    v-b-modal.create-campaign-modal
                  >
                    <v-icon class="mdi-plus-content2">mdi-plus</v-icon>
                  </v-btn>
                  to add a campaign</b
                >
              </h3>
            </div>
          </div>
          <div style="width: 100%" v-else>
            <demo />
          </div>
        </div>
      </div>
    </div>

    <!--  -->
    <!-- create campaign modal -->
    <div class="row mb-10">
      <modal name="my-first-modal" id="create-inf-modal">
        <create-campaign
          :ClientName="client_detail.name"
          @refresh="onAddCampaign"
        />
      </modal>
    </div>

    <!-- create allocation modal -->
    <b-modal
      id="create-allocation-modal"
      title="Overflowing Content"
      hide-header
      hide-footer
    >
      <create-allocation :ClientName="client_detail.name" />
    </b-modal>
  </div>
</template>

<script>
// import ApiService from "../../core/services/api.service";
import moment from "moment";
import { mapGetters } from "vuex";
import Dropdown7 from "@/view/content/dropdown/Dropdown7.vue";
import CellRendererAvatars from "@/view/pages/home/Planners/CellRendererAvatar.vue";
import CellRendererName from "@/view/pages/home/Planners/CellRendererName.vue";
import CellRendererMATERIALS from "@/view/pages/home/Planners/CellRendererMATERIALS.vue";
import CellRendererCoupon from "@/view/pages/home/Planners/CellRendererCoupon.vue";
import CellRendererDropdown from "@/view/pages/CellRenderDropdown.vue";
import CellRendererAction from "@/view/pages/CellRenderAction.vue";
import startplan from "@/view/pages/home/Planners/startplan.vue";
import { AgGridVue } from "ag-grid-vue";
import CreateCampaign from "../../pages/influencer_management/campaigns/Create_Campaign";
import CreateAllocation from "../../pages/influencer_management/allocations/create-allocation";
// import select_all from "@/view/components/select-all";
// import select_row from "@/view/components/select-row";
import demo from "@/view/components/demo";
import campaign from "@/view/components/influencer-campaign";
import allocation from "@/view/components/influencer-allocation";
import ads from "@/view/components/ads";
import ApiService from "../../../core/services/api.service";

export default {
  name: "influencer",
  components: {
    Dropdown7,
    AgGridVue,
    CellRendererAvatars,
    CellRendererName,
    CellRendererMATERIALS,
    CellRendererCoupon,
    startplan,
    CreateCampaign,
    CreateAllocation,
    CellRendererDropdown,
    CellRendererAction,
    // select_all,
    // select_row,
    demo,
    campaign,
    allocation,
    ads,
  },
  data() {
    return {
      campaignCount: {
        total_campaign: 0,
        total_cost: 0,
        total_profit: 0,
        total_billable: 0,
      },
      changedValue: null,
      campaigns: [],
      currentPage: 1,
      perPage: 10,
      selectedCampaignVal: null,
      selectedMonth: null,
      adsCount: 0,
      date: new Date().toISOString().substring(0, 7),
      calenderDate: this.$route.query.month
        ? this.$route.query.month
        : new Date().toISOString().substr(0, 7),
      menu: false,
      title: "Rick Grimes",
      Clients: [],
      filterClient: [],
      influencers: [],
      searchQuery: "",
      maxPageNumbers: 7,
      gridApi: null,
      StartingPlan: false,
      show_calendar: false,
      selectedAdsN: 0,
      currentSection: "campaign",
      // influencers: [],
    };
  },
  computed: {
    ...mapGetters(["InfCampaignGetters", "ClientGetters"]),
    client_detail() {
      return this.$store.getters.ClientGetters;
    },
    selectedCampaign: {
      get() {
        return this.$store.getters.InfCampaignGetters;
      },
      set(newName) {
        return newName;
      },
    },
  },
  watch: {
    //       influencers: function(){
    //           console.log("checking on campaign watcher")
    //           this.$emit('all-selected');
    //       }
    filterdClients() {
      this.monthFilter();
    },
  },
  beforeMount() {
    this.setCurrentSelection("campaign");
  },
  mounted() {
    this.fetchInfData();
    this.$store.dispatch("CLIENT_ACTION", this.$route.params.id);
  },
  updated() {
    // console.log("asdasd update cycle ")
  },
  methods: {
    show() {
      this.$modal.show("my-first-modal");
    },
    hide() {
      this.$modal.hide("my-first-modal");
    },
    counts(event) {
      this.campaignCount.total_campaign = event.length;
      this.campaignCount.total_profit = 0;
      this.campaignCount.total_billable = 0;
      this.campaignCount.total_cost = 0;
      for (let i = 0; i < event.length; i++) {
        this.campaignCount.total_profit += parseInt(event[i].exp_profit);
        this.campaignCount.total_billable += parseInt(event[i].billable_amount);
        this.campaignCount.total_cost += parseInt(event[i].budget);
      }
    },
    clicked(val) {
      this.selectedCampaignVal = val;
      this.adsCount = val.length;
      console.log("pp", val);
    },
    ChangeT(payload) {
      this.selectedAdsN = payload;
    },
    OnCalenderDateChange() {
      this.$refs.dialog.save(this.calenderDate);
      console.log(moment(this.calenderDate).format("M"));
      console.log(moment(this.calenderDate).format("Y"));
    },
    fetchInfData() {
      ApiService.get(`/influencers/${this.$route.params.id}`).then(
        (response) => {
          console.log(response.data);
        }
      );
      ApiService.get(`clients/${this.$route.params.id}`).then((response) => {
        this.Clients = response.data.data;
        this.filterClient = response.data.data;
      });
      this.monthFilter();
    },
    monthFilter(val) {
      if (val) {
        this.$router.replace({ query: { month: this.calenderDate } });
      }
      console.log(this.calenderDate, "calenderDate");
      let month = this.calenderDate.split("-")[1];
      this.selectedMonth = this.calenderDate;
      this.changedValue = this.selectedMonth;
      // this.Clients = this.filterClient.campaigns.filter(
      //   (x) => x.created_at.split("-")[1] == month
      // );
    },

    // selectRow(row){
    //   console.log('dont come heres')
    //     if(this.influencers.indexOf(row) !== -1){
    //         let index = this.influencers.indexOf(row);
    //         this.influencers.splice(index, 1);
    //         return;
    //     }
    //     this.influencers.push(row);
    //     },
    onGridReady(params) {
      // console.log(params);
      // this.gridApi = params.api;
      // this.columnApi = params.columnApi;
    },
    setCurrentSelection(val) {
      this.currentSection = val;
      this.selectedCampaign = [];
    },
    openModal() {
      this.show();
      // switch (this.currentSection) {
      //   case "campaign":
      //     this.$bvModal.show("create-campaign-modal");
      //     break;
      //   case "allocation":
      //     this.$bvModal.show("create-allocation-modal");
      //     break;
      //   default:
      //     break;
      // }
    },
    onSelectCampaign(campaign) {
      if (!campaign.node.selected) {
        this.selectedCampaign = null;
        return;
      }
      this.selectedCampaign = this.campaigns.find(
        (x) => x.id === campaign.rowIndex
      );
    },
    onAddCampaign() {
      this.$ref.campaignList.fetchInfData();
    },
  },
};
</script>

<style lang="scss">
// @import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.min.css";
// @import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-material.min.css";
.logo {
  width: 60px;
  height: 60px;
  margin: 5px;
}
.vm--container {
  position: fixed !important;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  height: unset;
  background: rgba($color: #000000, $alpha: 0.5);
  overflow-y: auto;
}
#create-inf-modal {
  .vm--overlay {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: transparent !important;
    z-index: unset !important;
    opacity: 1;
    overflow: auto;
  }
  .vm--modal {
    position: absolute;
    top: 2rem !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    height: unset !important;
    padding: 12px 1.5rem !important;
    border-radius: 12px !important;
    margin-top: 3rem;
    margin-bottom: 4rem !important;
    max-width: 630px !important;
    width: 90% !important;
  }
}
::-webkit-scrollbar {
  width: 0 !important;
}

.badge-success {
  color: #ffffff;
  background-color: #34d07b;
}
.inf-table {
  box-shadow: 0px 0px 8px 2px #f5f5f5;
  border-radius: 20px;
  padding: 1rem;
  padding-top: 4rem;
  min-height: 500px;
  // display: flex;
  align-items: center;
  font-family: "Poppins";
}

.plan-box {
  // background-color: rgb(241, 241, 241);
  // min-height: 100px;
  background-color: #f8fafc;
  min-height: 180px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-family: "Poppins";

  span:first-of-type {
    display: block;
    text-transform: uppercase;
    color: #000;
    font-weight: bold;
    font-size: 16px;
    font-family: "Poppins";
  }

  h1 {
    font-weight: bold;
    color: #000;
    font-size: 4rem;
    margin: 10px 0;
    font-family: "Poppins";
  }

  span:last-of-type {
    display: block;
    text-transform: capitalize;
    color: #000;
    font-weight: bold;
    font-size: 16px;
    font-family: "Poppins";
  }
}

.dropdown-heres {
  background: none !important;
  color: #f64e70 !important;
  border: none;
  font-weight: bold;
  font-size: 16px;
  font-family: "Poppins";
}

.planing {
  text-align: center;
  margin: 0 auto;
  box-shadow: 0px 0px 8px 2px #f5f5f5;
  border-radius: 20px;
  padding: 2rem;
  font-family: "Poppins";

  h1 {
    font-weight: bold;
    font-family: "Poppins";
  }
  .bolder-text {
    font-weight: bold;
    // font-weight: bold !important;
    font-weight: 900 !important;
    font-family: "Poppins";
  }

  .sub-title {
    color: #5ebb46;
    font-family: "Poppins";
    font-weight: bold;
  }

  .plan-boxes {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Poppins";
  }
}

.mdi-plus-bg {
  background-color: rgb(241, 241, 241) !important;
  box-shadow: none !important;
}
.mdi-plus-bg2 {
  background-color: rgb(63, 99, 218) !important;
  width: 25px !important;
  height: 25px !important;
  box-shadow: none !important;
}
.mdi-plus-content {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: black !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-box-pack: inherit;
  -ms-flex-pack: inherit;
  justify-content: inherit;
  line-height: normal;
  position: relative;
}
.mdi-plus-content2 {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: white !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-box-pack: inherit;
  -ms-flex-pack: inherit;
  justify-content: inherit;
  line-height: normal;
  position: relative;
}
.switch-btn-div {
  display: flex;
  justify-content: space-between;
}
.switch-to-calendar-button {
  // display: flex;
  margin-left: 10px;
  display: inline;
  align-items: right;
}
.mdi-round {
  display: flex;
  justify-content: space-between;
}
.switch-button-div {
  display: flex;
  justify-content: space-between;
}
.campaigns-header {
  padding-top: 15px;
  display: inline;
}
.section-btn {
  width: 190px;
  height: 42px;
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  color: #000000;
  font-family: "Poppins";
}
.active {
  background: #1374d6 0% 0% no-repeat padding-box;
  color: #fff;
}
.selected-info {
  display: block;
  font-size: 11px;
}

.ag-theme-material {
  .ag-header-cell-text {
    font-size: 11px;
    font-weight: bold;
    color: #000000;
    font-family: "Poppins";
  }
  // .ag-checkbox-input::after {
  //   background: #82ceca !important;
  // }
}

.ag-theme-material .ag-paging-button {
  background-color: #f3fcff;
  padding: 5px;
  border-radius: 5px;
  color: #82ceca;
  font-weight: bold;
}
@media (min-width: 576px) {
  .modal-dialog,
  .modal-md {
    width: 650px !important;
    max-width: 90% !important;
  }
}
.info {
  background-color: lightblue !important;
}
#view_plan___BV_modal_content_ {
  font-family: "Poppins";
}
.modal-date {
  color: red;
  font-size: 0.9rem;
}
.modal-comment-box {
  background: #f1f1f1;
  min-width: 20%;
  max-width: fit-content;
  height: 40%;
  margin: 1%;
  padding: 1%;
  border-radius: 10%;
}
// .modal-comment-box p{
//   color: red;
// }
.modal-comment-box h3 {
  font-weight: 900;
}
.infTable {
  padding-top: 0px !important;
}
.logo {
    height: 85px;
    width: 80px;
    border-radius: 20px;
    border: 2px solid white;
    box-shadow: gainsboro 0px 0px 5px;
}
</style>
