<template>
  <div class="mx-0 px-0 briefs ">
    <span class=" ">
      <span @click="view" class="svg-icon svg-icon-md svg-icon-danger">
        <!--begin::Svg Icon-->
        <inline-svg src="media/svg/icons/Files/icon_brief.svg" />
        <!--end::Svg Icon-->
      </span>
    </span>
  </div>
</template>

<script>
export default {
  methods: {
    view() {
      // console.log(this.params.data + 'ssssssadasdasd');
      this.params.context.parent.viewBriefAction(this.params.data);
    },
  },
};
</script>

<style scoped></style>
