<template>
  <v-app>
    <div class="create-campaign" id="infCreateCompaign">
      <b-row>
        <b-col>
          <span class="main-heading">Create Campaign</span>
          <br />
          <span class="sub-heading">for {{ ClientName }}</span>
        </b-col>
        <b-col cols="auto" role="button" @click="hide()" class="close">
          close
        </b-col>
      </b-row>

      <form ref="form" class="form-data">
        <!-- ADD DATE -->
        <b-form-group
          id="fieldset"
          class="mt-5"
          label="Add Date"
          label-for="add-date"
        >
          <b-row id="add-date">
            <b-col class="pb-0 pt-0">
              <b-form-group
                id="fieldset-start-date"
                label-cols-sm="4"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="10"
                label="from"
                label-for="start-date"
              >
                <b-form-datepicker
                  id="start-date"
                  v-model="startDate"
                  @context="onSelectStartDate"
                  :disabled="isFullMonthCampaign"
                  placeholder="1/10/2020"
                  locale="en"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                ></b-form-datepicker>
              </b-form-group>
            </b-col>

            <b-col class="pb-0 pt-0">
              <b-form-group
                id="fieldset-end-date"
                label-cols-sm="4"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="10"
                label="to"
                label-for="end-date"
              >
                <b-form-datepicker
                  id="end-date"
                  v-model="endDate"
                  :disabled="isFullMonthCampaign"
                  @context="onSelectEndDate"
                  placeholder="31/10/2020"
                  locale="en"
                  :min="min"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="startDate">
            <b-col class="pt-0">
              <b-form-checkbox
                class="custom-control custom-checkbox checkbox-xl"
                v-model="isFullMonthCampaign"
                @change="toggleFullMonth"
              >
                <span class="labels campaign-label">
                  FULL MONTH CAMPAIGN
                  <span style="color: #f76d67">(31 DAYS)</span>
                </span>
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-form-group>

        <!-- ADD NAME -->
        <b-form-group
          id="name-fieldset"
          class="input-label"
          label="Add Campaign Name"
          label-for="compaignName"
        >
          <b-form-input
            id="compaignName"
            v-model="addName"
            class="input-field custom-input"
            type="text"
            required
            placeholder="Add name here"
          />
        </b-form-group>

        <b-row class="position-relative">
          <!-- ADD BUDGET -->
          <b-col class="col-6 col-md-4">
            <b-form-group
              id="budget-fieldset"
              class="input-label mt-5 w-100 px-0"
              label="Add Budget"
              label-for="budget"
            >
              <b-input-group type="number" class="mt-3">
                <template #prepend>
                  <b-dropdown :text="budget_currency" class="currency-dropdown">
                    <b-dropdown-item @click="budget_currency = 'AED'"
                      >AED</b-dropdown-item
                    >
                    <b-dropdown-item @click="budget_currency = 'USD'"
                      >USD</b-dropdown-item
                    >
                    <b-dropdown-item @click="budget_currency = 'SAR'"
                      >SAR</b-dropdown-item
                    >
                    <b-dropdown-item @click="budget_currency = 'KWD'"
                      >KWD</b-dropdown-item
                    >
                  </b-dropdown>
                </template>

                <b-form-input
                  type="number"
                  v-on:input="profit"
                  v-model="addBudget"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col class="col-6 col-md-4">
            <!-- ADD BILLABLE AMOUNT -->
            <b-form-group
              id="budget-fieldset"
              class="input-label mt-5 px-0"
              label="Add Billable Amount"
              label-for="billableAmount"
            >
              <b-input-group type="number" class="mt-3">
                <template #prepend>
                  <b-dropdown :text="budget_currency" class="currency-dropdown">
                    <b-dropdown-item @click="budget_currency = 'AED'"
                      >AED</b-dropdown-item
                    >
                    <b-dropdown-item @click="budget_currency = 'USD'"
                      >USD</b-dropdown-item
                    >
                    <b-dropdown-item @click="budget_currency = 'SAR'"
                      >SAR</b-dropdown-item
                    >
                    <b-dropdown-item @click="budget_currency = 'KWD'"
                      >KWD</b-dropdown-item
                    >
                  </b-dropdown>
                </template>

                <b-form-input
                  type="number"
                  v-on:input="profit"
                  v-model="billbleAmount"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col class="col-6 col-md-4 mt-md-5 mt-0 w-100">
            <label class="d-block">Expected Profit</label>
            <div class="profit-text">
              {{ budget_currency }} {{ expected_profit }}
            </div>
          </b-col>
          <span class="devide-line"></span>
        </b-row>

        <!-- DESCREAPTION -->
        <div class="mt-5">
          <div class="brief-text">Add Brief</div>
          <b-form-group
            class="input-label"
            label="Description"
            label-for="compaignBrief"
          >
            <b-form-textarea
              v-model="description"
              type="text"
              required
              placeholder=""
            />
          </b-form-group>
        </div>

        <!-- ADD REQUIREMENT -->
        <b-form-group class="input-label col-12 pl-0 mb-0">
          <label
            >Requirements
            <v-btn
              @click="add"
              class="mx-1 ml-4 mr-4 mdi-plus-bg2"
              fab
              dark
              color="blue"
            >
              <v-icon class="mdi-plus-content2">mdi-plus</v-icon>
            </v-btn>
          </label>
          <b-row v-if="!showRequirement">
            <b-col class="pb-0 col-12" v-for="(input, k) in inputs" :key="k">
              <b-form-group class="input-label">
                <b-input-group>
                  <b-form-input
                    id="roi"
                    v-model="input.name"
                    class="input-field custom-input"
                    type="text"
                  />
                  <b-input-group-append @click="removeRequirement(k)">
                    <b-input-group-text>
                      <b-icon icon="x" font-scale="2" />
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>
        <!-- ADD REQUIREMENT -->

        <!-- INFUENCER DETAIL -->
        <b-form-group class="input-label col-12 pb-8 position-relative">
          <span class="devide-line-2"></span>
          <label class="inf-detail-label"
            >Influencer Details
            <v-btn
              @click="addInfuencerFields"
              class="mx-1 ml-4 mdi-plus-bg2"
              fab
              dark
              color="blue"
            >
              <v-icon class="mdi-plus-content2">mdi-plus</v-icon>
            </v-btn>
          </label>
          <b-row
            class="influencer position-relative mt-3"
            v-for="(field, f) in infuencerFields"
            :key="f"
          >
            <span class="inf-field-remove-btn">
              <v-btn icon color="dark" @click="removeINF(f)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </span>
            <!-- PLATFORMS -->
            <b-col class="col-6 col-md-3 border-right py-7 px-5">
              <b-form-group class="input-label mb-0">
                <label class="sub-label">Platform </label>
                <v-select
                  color="#00a4f3"
                  background-color="#f3fcff"
                  outlined
                  dense
                  height="45"
                  hide-details="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  v-model="field.platform"
                  :items="itemss"
                  placeholder="Select"
                  :persistent-hint="false"
                >
                  <template v-slot:selection="{ item }">
                    <div>
                      <img :src="item.image" class="select-image" />
                    </div>
                  </template>
                  <template v-slot:item="{ item }">
                    <img :src="item.image" class="select-image" />
                    <span class="socialText"> {{ item.name }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <!-- PLATFORMS -->
            <!-- INFLUENSERS -->
            <b-col class="col-6 col-md-3 border-right py-7 px-5">
              <b-form-group class="input-label mb-0">
                <label class="sub-label">No. of Influencers </label>
                <b-form-input
                  id="roi"
                  v-model="field.no_of_influencers"
                  class="input-field custom-input mb-0"
                  type="number"
                  placeholder="add here"
                />
              </b-form-group>
            </b-col>
            <!-- INFLUENSERS -->
            <!-- VIEWS -->
            <b-col class="col-6 col-md-3 border-right py-7 px-5">
              <label class="sub-label">Views </label>
              <b-form-group class="input-label mb-0">
                <b-form-input
                  id="orders"
                  v-model="field.views"
                  class="input-field custom-input"
                  type="number"
                  placeholder="add here"
                />
              </b-form-group>
            </b-col>
            <!-- VIEWS -->
            <!-- LOCATION -->
            <b-col class="col-6 col-md-3 py-7 px-3">
              <b-form-group class="input-label mb-0">
                <label class="sub-label">Location </label>
                <v-autocomplete
                  color="#00a4f3"
                  background-color="#f3fcff"
                  outlined
                  dense
                  height="45px"
                  hide-details="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  v-model="field.location"
                  placeholder="Select"
                  :items="cities"
                  :persistent-hint="false"
                  class="py-0"
                >
                  <!-- <template v-slot:selection="{ item }">
                    <div v-bind:class="{ loctaionText: item.length > 7 }">
                      <div class="loctaionText">
                      {{ item }}
                    </div>
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item }}
                  </template> -->
                </v-autocomplete>
              </b-form-group>
            </b-col>
            <!-- LOCATION -->
          </b-row>
          <!-- <div class="text-end" v-show="infuencerFields.length > 1">
            <button @click="removeINF" class="remove-btn mt-3">Remove</button>
          </div> -->
        </b-form-group>
        <!-- INFUENCER DETAIL -->

        <!-- ADD OFFERS -->
        <b-form-group class="input-label mt-5 col-12 mb-0 pb-0 pl-0">
          <label
            >Add Offers
            <v-btn
              @click="toggleOffersOption"
              class="mx-1 ml-4 mr-4 mdi-plus-bg2"
              fab
              dark
              color="blue"
            >
              <v-icon class="mdi-plus-content2">mdi-plus</v-icon>
            </v-btn>
          </label>
        </b-form-group>
        <v-row v-if="showOffersSection">
          <v-col cols="12">
            <multiselect
              v-model="value"
              tag-placeholder="Add this as new offer"
              placeholder="Search or add a offer"
              track-by="title"
              :options="options"
              :multiple="true"
              :taggable="true"
              label="title"
              @tag="addTag"
            ></multiselect>
          </v-col>
        </v-row>

        <!-- ADD COUPONS -->
        <b-form-group
          id="name-fieldset"
          class="input-label mt-5"
          label="Add Coupons"
          label-for="coupons"
        >
          <multiselect
            v-model="couponsData"
            tag-placeholder="Add this as new offer"
            placeholder="Search or add a offer"
            track-by="name"
            :options="couponsList"
            :multiple="true"
            :taggable="true"
            label="name"
            @tag="addCouponTag"
          ></multiselect>
        </b-form-group>

        <!-- ADD BUTTON -->
        <b-form-group class="mt-5">
          <b-button
            class="add-btn"
            :disabled="!isFormValidated"
            @click="handleSubmit"
            >Add <b-spinner v-if="isLoading" small type="grow"></b-spinner>
          </b-button>
        </b-form-group>
      </form>
    </div>
  </v-app>
</template>
<style lang="scss">
.loctaionText {
  white-space: nowrap;
  overflow: hidden;
  margin-top: 40px;
}
.select-image {
  width: 25px;
}
.flag-image {
  width: 18px;
  height: 15px;
}
.socialText {
  font-weight: bold;
  text-indent: 9px;
}
.influencer {
  background: #f3f6f9;
  border-radius: 12px;
}
.inf-field-remove-btn {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9999;
}
.border-right {
  border-right: 2px solid #ccc !important;
}

.devide-line {
  position: absolute;
  width: calc(100% + 18px);
  height: 1px;
  background-color: #e2e2e2;
  bottom: 0;
  left: -9px;
}
.devide-line-2 {
  position: absolute;
  width: calc(100% + 3.5rem);
  height: 1px;
  background-color: #e2e2e2;
  bottom: 0;
  left: -1.75rem;
}
.brief-text {
  font-size: 20px;
  font-weight: 700;
}
.create-campaign,
.edit-campaign {
  font-family: "Poppins";

  .main-heading {
    font-weight: bold;
    font-size: 24px !important;
    color: #000000;
    font-family: Poppins;
  }
  .sub-heading {
    font-size: 18px !important;
    font-weight: 600;
    color: #939393;
  }
  .close {
    font-size: 16px !important;
    font-weight: 600;
    color: #000000;
    opacity: 0.36;
  }
  .profit-text {
    font-size: 22px !important;
    font-weight: 600;
    line-height: 2.25;
  }
  .inf-detail-label {
    margin-left: -12px;
  }
  .form-data {
    margin-top: 10px;
    .b-form-datepicker {
      background: #f3fcff 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
      height: 45px !important;
      font-size: 16px !important;
    }
    select,
    input {
      height: 45px !important;
      background: #f3fcff 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
      font-size: 16px !important;
      color: #000000;
    }
    textarea {
      min-height: 200px;
      background: #f3fcff 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
      font-size: 16px !important;
      color: #000000;
    }
    #checkbox-1::after {
      width: 25px !important;
      height: 23px !important;
      background: #00a4f3 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
    }
    .checkbox-xl .custom-control-label::before,
    .checkbox-xl .custom-control-label::after {
      width: 25px !important;
      height: 23px !important;
      border-radius: 8px;
      opacity: 1;
    }
    .checkbox-xl .custom-control-label {
      padding-left: 24px;
      padding-top: 0px;
    }

    .multiselect__content-wrapper {
      ul {
        padding-left: 0;
      }
    }
    .multiselect__tags {
      border: none !important;
      min-height: 45px !important;
      background: #f3fcff 0% 0% no-repeat padding-box;
      border-radius: 8px;
      font-size: 16px !important;
      color: #000000;
      border: 1px solid #e4e6ef !important;
    }
    .multiselect__tag {
      background-color: #000;
    }
    .multiselect__tag-icon:hover {
      background: var(--v-accent-base);
    }
    .multiselect__select::before {
      top: 90%;
    }
    .v-text-field .v-input__slot {
      border-radius: 8px;
      border: none !important;
    }
    label {
      align-self: center;
      font-size: 14px !important;
      color: #000000;
    }
    .sub-label {
      font-size: 12px !important;
      font-weight: 600 !important;
    }
    .custom-control-input {
      display: none;
    }
    .campaign-label {
      text-align: left;
      font: normal normal bold 14px/22px Poppins;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
    }
    .add-btn {
      float: right;
      width: 150px;
      height: 45px;
      background: #1374d6 0% 0% no-repeat padding-box;
      border-radius: 10px;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 1.6px;
      color: #ffffff;
    }
    .payout-dropdown {
      button,
      button:focus {
        background: black;
        color: #fff;
        font-size: 16px !important;
        font: normal normal bold 12px/25px Poppins;
      }
      button::after {
        font-size: 16px;
        color: #fff;
      }
    }
    .currency-dropdown {
      button,
      button:focus {
        background: #f3fcff;
        color: #000000;
        font-size: 16px;
        font-weight: 500;
      }
      button::after {
        font-size: 10px;
        color: #000000;
      }
    }
    .v-input input,
    .v-input textarea:focus,
    .v-input textarea:active {
      background-color: transparent;
      margin-top: 0.5rem;
    }
    .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed
      .v-select__selections {
      min-height: 45px !important;
      font-size: 14px !important;
    }
    .currency-dropdown .dropdown-menu {
      padding: 0.2rem;
    }
  }
}
.v-text-field--outlined.v-input--dense > .v-input__control > .v-input__slot {
  min-height: 44px !important;
  border: none;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import ApiService from "../../../../core/services/api.service";
import Multiselect from "vue-multiselect";
import cities from "../../../../view/components/cities.json";

export default {
  name: "create-campaign",
  components: { Multiselect },
  props: ["ClientName"],
  data: () => ({
    startDate: "",
    couponsList: [],
    couponsData: [],
    value: [],
    options: [],
    couponOptions: [],
    startCompleteDate: null,
    endCompleteDate: null,
    inputs: [],
    infuencerFields: [],
    itemss: [
      {
        name: "Snapchat",
        image:
          "https://cdn2.iconfinder.com/data/icons/black-white-social-media/64/social_media_logo_snapchat-1024.png",
      },
      {
        name: "Facebook",
        image:
          "https://cdn2.iconfinder.com/data/icons/black-white-social-media/32/online_social_media_facebook-512.png",
      },
      {
        name: "Twitter",
        image:
          "https://cdn2.iconfinder.com/data/icons/black-white-social-media/32/twitter_online_social_media-512.png",
      },
      {
        name: "Instagram",
        image:
          "https://cdn2.iconfinder.com/data/icons/black-white-social-media/32/instagram_online_social_media_photo-512.png",
      },
      {
        name: "YouTube",
        image:
          "https://cdn2.iconfinder.com/data/icons/black-white-social-media/32/youtube_online_social_media_tube-512.png",
      },
      {
        name: "TikTok",
        image:
          "https://cdn2.iconfinder.com/data/icons/social-media-2421/512/TikTok-512.png",
      },
    ],
    locations: [
      {
        image: "https://cdn.countryflags.com/thumbs/china/flag-square-250.png",
        name: "China",
      },
      {
        image: "https://cdn.countryflags.com/thumbs/india/flag-square-250.png",
        name: "India",
      },
      {
        image: "https://cdn.countryflags.com/thumbs/europe/flag-square-250.png",
        name: "Europe",
      },
      {
        image:
          "https://cdn.countryflags.com/thumbs/united-states-of-america/flag-square-250.png",
        name: "America",
      },
      {
        image: "https://cdn.countryflags.com/thumbs/japan/flag-square-250.png",
        name: "Japan",
      },
    ],
    endDate: "",
    isFullMonthCampaign: false,
    name: "",
    addName: "",
    budget: "",
    addBudget: "",
    billbleAmount: "",
    expected_profit: 0,
    target: {
      target: "",
      revenue: "",
      roi: "",
      orders: "",
      commission: "",
      target_currency: "AED",
      revenue_currency: "AED",
      commission_currency: "AED",
    },
    budget_currency: "AED",
    payout: {
      payoutType: "CPO",
      amount: "",
      currencyType: "AED",
    },
    coupons: [],
    products: "",
    geos: "",
    couponsArray: [],
    min: null,
    max: null,
    firstDay: null,
    lastDay: null,
    showRequirement: false,
    showPayout: false,
    showOffersSection: false,
    focusOnProducts: false,
    focusOnGeo: false,
    isLoading: false,
    description: "",
    reqValue: [],
    offersSelected: [],
    cities: cities,
  }),
  methods: {
    hide() {
      this.$modal.hide("my-first-modal");
    },
    fetchCities() {
      ApiService.get(`/countries/all-city`).then((response) => {
        console.log(response, "welcome");
        this.cities = response.data.data;
        console.log("this.cities", this.cities);
      });
      console.log("Cities");
    },
    closeInput(index) {
      this.inputs.splice(index, 1);
    },
    profit() {
      this.expected_profit = this.billbleAmount - this.addBudget;
    },
    add() {
      this.inputs.push({
        name: "",
      });
    },
    removeRequirement(index) {
      this.inputs.splice(index, 1);
    },
    addInfuencerFields() {
      this.infuencerFields.push({
        platform: "",
        no_of_influencers: "",
        views: "",
        location: "",
      });
    },
    removeINF(index) {
      console.log("pppPPPP", index);
      this.infuencerFields.splice(index, 1);
    },
    addTag(newTag) {
      const tag = {
        title: newTag,
      };
      this.options.title.push(tag);
      this.value.push(tag);
    },
    addCouponTag(newTag) {
      const tag = {
        code: newTag,
      };
      this.couponOptions.code.push(tag);
      this.couponValue.push(tag);
    },
    fetchData() {
      // fetch offers
      ApiService.get(`/offers?client_id=${this.$route.params.id}`).then(
        (response) => {
          this.options = response.data.data.data;
        }
      );
      // fetch coupons
      ApiService.post(`/coupons/get-coupon-by-client`, {
        client_id: this.$route.params.id,
      }).then((response) => {
        var couponData = response.data.data;
        for (let i = 0; i < couponData.length; i++) {
          couponData[i].name = couponData[i].code;
        }
        this.couponsList = couponData;
      });
    },
    /* event triggered on selecting start date */
    onSelectStartDate(ctx) {
      this.startCompleteDate = ctx.activeDate;
      this.firstDay = new Date(
        ctx.activeDate.getFullYear(),
        ctx.activeDate.getMonth(),
        1
      );
      this.lastDay = new Date(
        ctx.activeDate.getFullYear(),
        ctx.activeDate.getMonth() + 1,
        0
      );

      // set date min and max range
      this.min = new Date(this.firstDay);
      this.min.setDate(ctx.activeDate.getDate());
      this.max = new Date(this.lastDay);

      // auto fill campaign name
      this.name = this.getAutoCampaignName();
    },

    /* event triggered on selecting end date */
    onSelectEndDate(ctx) {
      if (!this.endDate || this.isFullMonthCampaign) {
        return;
      }
      this.endCompleteDate = ctx.activeDate;
      // auto fill campaign name
      this.name = this.getAutoCampaignName();
    },

    /* toggle full month campaign  */
    toggleFullMonth(val) {
      // set month start and end date if user select full month campaign check.
      if (val) {
        this.startDate = this.toJSONLocal(this.firstDay);
        this.endDate = this.toJSONLocal(this.lastDay);
      }
    },
    toJSONLocal(date) {
      var local = new Date(date);
      local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      return local.toJSON().slice(0, 10);
    },

    /* get formatted auto campaign name according to dates. */
    getAutoCampaignName() {
      let month = this.startCompleteDate.toLocaleString("default", {
        month: "long",
      });
      let dateRange =
        this.isFullMonthCampaign || !this.endCompleteDate
          ? ""
          : `${this.startCompleteDate.getDate()} - ${this.endCompleteDate.getDate()}`;
      let year = this.startCompleteDate.getFullYear();
      return `${this.addName}, ${dateRange} ${month} ${year} Campaign`;
    },

    toggleRequirementOption() {
      this.showRequirement = !this.showRequirement;
    },
    toggleOffersOption() {
      this.showOffersSection = !this.showOffersSection;
    },
    handleSubmit() {
      var offers = [];
      var coupons = [];
      // set coupons
      if (this.couponsData.length !== 0) {
        for (let i = 0; i < this.couponsData.length; i++) {
          coupons.push(this.couponsData[i]._id);
        }
      }
      //set offers
      if (this.value.length !== 0) {
        for (let i = 0; i < this.value.length; i++) {
          offers.push(this.value[i]._id);
        }
      }
      for (var i = 0; i < this.inputs.length; i++) {
        this.reqValue.push(this.inputs[i].name);
      }
      for (var i = 0; i < this.value.length; i++) {
        this.offersSelected.push(this.value[i]._id);
      }
      // auto fill campaign name
      // this.name = this.getAutoCampaignName();
      let dataToPost = {
        exp_profit: this.expected_profit,
        billable_amount: this.billbleAmount,
        client_id: this.$route.params.id,
        title: this.addName,
        description: this.description,
        status: "new",
        budget: this.addBudget,
        budget_currency: this.budget_currency,
        target: this.target.revenue,
        target_sales_currency: "AED",
        start: this.startDate,
        end: this.endDate,
        platform_type: "INFLUENCER",
        roi: this.target.roi,
        revenue: this.target.revenue,
        commission: this.target.commission,
        orders: this.target.orders,
        target_currency: this.target.target_currency,
        requirements: this.reqValue,
        influencer_details: this.infuencerFields,
        offers: offers,
        coupons: coupons,
      };
      console.log("create Campaign", dataToPost);
      this.isLoading = true;
      ApiService.post("/campaigns/create", dataToPost)
        .then((response) => {
          this.fetchData();
          console.log("response ", response.data.data.message);
          this.isLoading = false;
          location.reload();
          this.$bvModal.hide("create-campaign-modal");
          this.$emit("refresh", response.data.data.message);
        })
        .catch(function(error) {
          this.$bvToast.toast(`${error.message[0]}`, {
            title: "Wrong Data",
            autoHideDelay: 5000,
            toaster: "b-toaster-top-center",
            variant: "success",
            appendToast: append,
          });
        });
    },
  },
  computed: {
    isFormValidated() {
      if (
        this.addName === "" ||
        this.startDate === "" ||
        this.endDate === "" ||
        this.addBudget === ""
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.fetchData();
    // this.fetchCities();
  },
};
</script>
<style scoped>
#budget-fieldset .input-group {
  width: 100% !important;
}
</style>
