<template>
  <div v-if="currentSelect === 'campaign'">
    <ag-grid-vue
      class="ag-grid-table ag-theme-material table table-responsive"
      :context="context"
      :gridOptions="gridOptions"
      :suppressDragLeaveHidesColumns="true"
      @grid-ready="onGridReady"
      :defaultColDef="defaultColDef"
      :rowData="campaigns"
      :animateRows="true"
      rowSelection="multiple"
      @rowSelected="onSelectCampaign"
      :getRowHeight="getRowHeight"
    >
    </ag-grid-vue>

    <span class="bottom">
      <b-pagination
        style="display: inline-flex"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
      <span style="float: right" class="pr-5">
        <b-button
          class="deleteAll-btn"
          v-b-modal.delete-campaign
          :disabled="!selectedCampaign.length"
          >Delete All
        </b-button>
      </span>
    </span>

    <!-- Edit Modal Component -->

    <modal name="edit-modal" id="create-inf-modal">
      <edit-campaign :ClientName="ClientName" :row="edit_row" v-if="edit_row" />
    </modal>
    <!-- view Plan Modal -->
    <b-modal
      id="view_plan"
      dialog-class="view-media-plan-modal"
      size="sm"
      centered
      :hide-footer="true"
      :hide-header="true"
    >
      <MediaPlan :campaign="view_plan" @fetch="fetchData" />
    </b-modal>
    <b-modal id="view_brief" :hide-footer="true" :hide-header="true">
      <b-row>
        <b-col>
          <h3 class="brief_title">{{ brief_arr.title }} Brief</h3>
          <p class="brief_date">
            {{ brief_arr.start | moment("DD") }}-{{
              brief_arr.end | moment("DD MMMM, YYYY")
            }}
          </p>
        </b-col>
        <b-col
          cols="auto"
          role="button"
          @click="$bvModal.hide('view_brief')"
          class="close"
        >
          close
        </b-col>
      </b-row>

      <form ref="form" class="form-data">
        <!-- ADD DESCRIPTION -->
        <b-form-group>
          <label>
            <span class="label_weight"> Description</span>
          </label>
          <b-textarea
            id="description"
            class="input_description text"
            type="text"
            required
            v-model="brief_arr.description"
            placeholder="Description"
          >
          </b-textarea>
        </b-form-group>
        <!-- ADD REQUIREMENTS -->
        <div class="mx-5 mb-3">
          <b-row>
            <label>
              <span class="label_weight"> Requirements</span>
              <v-btn
                @click="addRequirement"
                class="mx-1 ml-4 mr-4 mdi-plus-bg2"
                style="height: 18px !important; width: 18px !important"
                fab
                dark
                color="blue"
              >
                <v-icon class="mdi-plus-content2" style="font-size: 13px"
                  >mdi-plus</v-icon
                >
              </v-btn>
            </label>
          </b-row>
          <b-row>
            <b-col
              class="pb-0 pl-0 col-6 col-sm-4"
              v-for="(item, index) in requirement"
              :key="index"
            >
              <b-form-group class="input-label">
                <b-input-group>
                  <b-form-input
                    v-model="item.name"
                    class="input-field custom-input text"
                    type="text"
                  />
                  <b-input-group-append @click="closeInput(index)">
                    <b-input-group-text>
                      <b-icon icon="x" font-scale="2" />
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <!-- ADD INFLUENCER DETAILS -->
        <div class="mx-5">
          <b-row>
            <label
              ><span class="label_weight"> Influencer Details</span>
              <v-btn
                @click="addInfField"
                class="mx-1 ml-4 mr-4 mdi-plus-bg2"
                fab
                dark
                color="blue"
                style="height: 18px !important; width: 18px !important"
              >
                <v-icon class="mdi-plus-content2" style="font-size: 13px"
                  >mdi-plus</v-icon
                >
              </v-btn>
            </label>
          </b-row>
          <b-row fluid>
            <b-col
              class="col-6 pl-0 text_ mb-15"
              v-for="(infData, index) in influencer"
              :key="index"
            >
              <v-text-field
                outlined
                v-model="infData.views"
                hide-details="true"
                class="socialText"
              >
                <template #prepend-inner>
                  <v-select
                    outlined
                    append-icon=""
                    :menu-props="{ bottom: true, offsetY: true }"
                    v-model="infData.platform"
                    :items="plateforms"
                    placeholder="Select"
                    id="plateforms"
                    class="p-0 text-center"
                  >
                    <template v-slot:selection="{ item }" class="d-block">
                      <div class="text-center m-auto">
                        <span class="infno">{{
                          infData.no_of_influencers
                        }}</span>
                        <img
                          :src="item.image"
                          style="width: 20px; height: 20px"
                          class="select-image m-auto"
                        />
                      </div>
                    </template>
                    <template v-slot:item="{ item }">
                      <img
                        style="width: 20px; height: 20px"
                        :src="item.image"
                        class="select-image"
                      />
                      <span class="socialText"> {{ item.name }}</span>
                    </template>
                  </v-select>
                </template>
                <template #append>
                  <v-select
                    outlined
                    append-icon=""
                    :menu-props="{ bottom: true, offsetY: true }"
                    v-model="infData.location"
                    placeholder="Select"
                    :items="cities"
                    id="countary"
                    class="p-0 text-center"
                  >
                    <template v-slot:selection="{ item }">
                      <div class="socialText">
                        {{ item }}
                      </div>
                    </template>
                    <template v-slot:item="{ item }">
                      {{ item }}
                    </template>
                  </v-select>
                </template>
              </v-text-field>
            </b-col>
          </b-row>
        </div>
        <!-- ADD BUTTON -->
        <b-form-group class="mt-10">
          <b-button @click="handleSubmit" type="submit" class="add-btn mt-5"
            >SAVE <b-spinner v-if="isLoading" small type="grow"></b-spinner
          ></b-button>
        </b-form-group>
      </form>
    </b-modal>
    <div class="deleteAllModal">
      <b-modal
        dialog-class="deleteAllModal"
        id="delete-campaign"
        ok-variant="danger"
        ok-title="Remove"
        @ok="deleteAllConfirmation"
        size="sm"
        centered
      >
        <h5>Are you sure you want to delete all following offers?</h5>
        <ul style="margin-left: 20px">
          <li v-for="(item, i) in selectedCampaign" v-bind:key="i">
            {{ item.title }}
          </li>
        </ul>
      </b-modal>
    </div>
  </div>
  <div v-else>
    <ag-grid-vue
      v-show="isShow"
      class="ag-grid-table ag-theme-material table table-responsive"
      :context="context"
      :gridOptions="gridOptions"
      :suppressDragLeaveHidesColumns="true"
      @grid-ready="onGridReady"
      :defaultColDef="defaultColDef"
      :rowData="campaigns"
      :animateRows="true"
      rowSelection="multiple"
      @rowSelected="onSelectCampaign"
    >
    </ag-grid-vue>
    <ads
      @changeTitle="ChangeT($event)"
      :selectedCampaign="selectedCampaignVal"
      :isInfluencer="true"
      :platform_type="'INFLUENCER'"
    />
  </div>
</template>
<script>
import cities from "./cities.json";
import ApiService from "../../core/services/api.service";
import { AgGridVue } from "ag-grid-vue";
import CellRendererDropdown from "@/view/components/InfCampCellRenderStatus.vue";
import CellRendererAction from "@/view/pages/CellRenderAction.vue";
import CellRendererComment from "@/view/pages/CellRenderCommentModal.vue";
import CellRenderPeriod from "@/view/pages/CellRenderDate.vue";
import CellRenderName from "@/view/pages/CellRenderCampaignName.vue";
import keyMetrics from "@/view/pages/keyMetrics.vue";
import agGridBrief from "@/view/pages/agGridBrief.vue";
import MediaPlan from "@/view/components/MediaPlan";
import editCampaign from "../pages/influencer_management/campaigns/Edit_Campaign.vue";
import ads from "@/view/components/ads";

export default {
  props: ["ClientName", "campData", "currentSelect"],
  name: "campaign",
  components: {
    AgGridVue,
    CellRendererDropdown,
    CellRenderPeriod,
    CellRenderName,
    MediaPlan,
    keyMetrics,
    CellRendererAction,
    CellRendererComment,
    editCampaign,
    agGridBrief,
    ads,
  },
  watch: {
    campData: function(newVal, oldVal) {
      // watch it
      this.filterData(newVal);
    },
    currentSelect: function(newVal, oldVal) {
      // watch it
      // this.filterData(newVal);
      console.log(newVal);
    },

    selectedCampaign: function(oldCode, newCode) {
      this.changeSelection();
    },
    currentPage() {
      this.fetchData();
    },
  },
  data() {
    return {
      getRowHeight: null,
      selectedCampaignVal: null,
      isShow: false,
      camp: true,
      currentdDate: new Date().toISOString().substring(0, 7),
      cities: cities,
      campaignData: [],
      currentPage: 1,
      rows: 0,
      perPage: 15,
      RequirementsArr: [],
      requirement: [],
      influencer: [],
      isLoading: false,
      showReq: true,
      inputs: [],
      selected: null,
      showInfDet: false,
      context: {},
      editable_row: null,
      searchQuery: "",
      maxPageNumbers: 7,
      gridApi: null,
      StartingPlan: false,
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        flex: 1,
        minWidth: 100,
        filter: true,
        resizable: true,
      },
      SelectedPlateform: null,
      SelectedLocation: null,
      plateforms: [
        {
          name: "Snapchat",
          image:
            "https://cdn2.iconfinder.com/data/icons/black-white-social-media/64/social_media_logo_snapchat-1024.png",
        },
        {
          name: "Facebook",
          image:
            "https://cdn2.iconfinder.com/data/icons/black-white-social-media/32/online_social_media_facebook-512.png",
        },
        {
          name: "Twitter",
          image:
            "https://cdn2.iconfinder.com/data/icons/black-white-social-media/32/twitter_online_social_media-512.png",
        },
        {
          name: "Instagram",
          image:
            "https://cdn2.iconfinder.com/data/icons/black-white-social-media/32/instagram_online_social_media_photo-512.png",
        },
        {
          name: "YouTube",
          image:
            "https://cdn2.iconfinder.com/data/icons/black-white-social-media/32/youtube_online_social_media_tube-512.png",
        },
        {
          name: "TikTok",
          image:
            "https://cdn2.iconfinder.com/data/icons/social-media-2421/512/TikTok-512.png",
        },
      ],
      locations: [
        {
          image:
            "https://cdn.countryflags.com/thumbs/china/flag-square-250.png",
          name: "China",
        },
        {
          image:
            "https://cdn.countryflags.com/thumbs/india/flag-square-250.png",
          name: "India",
        },
        {
          image:
            "https://cdn.countryflags.com/thumbs/europe/flag-square-250.png",
          name: "Europe",
        },
        {
          image:
            "https://cdn.countryflags.com/thumbs/united-states-of-america/flag-square-250.png",
          name: "America",
        },
        {
          image:
            "https://cdn.countryflags.com/thumbs/japan/flag-square-250.png",
          name: "Japan",
        },
      ],
      gridOptions: {
        frameworkComponents: {
          //   CellRendererAvatars: CellRendererAvatars,
          //   CellRendererName: CellRendererName,
          //   CellRendererMATERIALS: CellRendererMATERIALS,
          //   CellRendererCoupon: CellRendererCoupon,
          //   CellRendererDropdown: CellRendererDropdown,
          //   CellRendererAction: CellRendererAction
        },
        context: {
          componentParent: this,
        },
        rowHeight: 60,
        columnDefs: [
          {
            maxWidth: 50,
            checkboxSelection: true,

            headerCheckboxSelection: true,
          },
          {
            headerName: "CAMPAIGN NAME",
            field: "title",
            minWidth: 190,
            filter: true,
            suppressMenu: false,
            cellRendererFramework: "CellRenderName",
          },
          {
            headerName: "DATE",
            filter: true,
            cellRendererFramework: "CellRenderPeriod",
          },
          {
            headerName: "BUDGET",
            field: "budget",
            filter: true,
            minWidth: 130,
            valueGetter(params) {
              return `${params.data.budget} ${params.data.budget_currency}`;
            },
          },
          {
            headerName: "PROFIT",
            field: "exp_profit",
            filter: false,
            minWidth: 130,
            valueGetter(params) {
              return `${params.data.exp_profit} ${params.data.exp_profit_currency}`;
            },
          },
          {
            headerName: "BILLABLE AMOUNT",
            field: "billable_amount",
            filter: false,
            minWidth: 150,
            valueGetter(params) {
              return `${params.data.billable_amount} ${params.data.exp_profit_currency}`;
            },
          },
          {
            headerName: "BRIEF",
            // field: "actual_cost",
            cellRendererFramework: "agGridBrief",
            suppressSorting: true,
            maxWidth: 100,
          },
          // {
          //   headerName: "COMMISSION",
          //   field: "commission",
          //   filter: false,
          //   valueGetter(params) {
          //     return `${params.data.billable_amount} ${params.data.billable_amount_currency}`;
          //   },
          // },
          // {
          //   headerName: "PROFIT",
          //   field: "profit",
          //   filter: false,
          // },
          // {
          //   headerName: "KeyMetrics",
          //   Width: 100,
          //   cellRendererFramework: "keyMetrics",
          //   suppressSorting: true,
          //   valueGetter(params) {
          //     return `${params.data.billable_amount} ${params.data.billable_amount_currency}`;
          //   },
          // },
          {
            headerName: "STATUS",
            minWidth: 150,
            cellRendererFramework: "CellRendererDropdown",
            suppressSorting: true,
          },

          {
            headerName: "ACTIONS",
            maxWidth: 120,
            cellRendererFramework: "CellRendererAction",
            // cellRenderer:(params)=>{
            //     return `<button  class="fa fa-arrow-alt-circle-right"></button>`
            // },

            suppressSorting: false,
            selectable: false,
          },
        ],
      },
      campaigns: [],
      selectedCampaign: [],
      period_gridOptions: {
        frameworkComponents: {
          // CellRendererAvatars: CellRendererAvatars,
          // CellRendererName: CellRendererName,
          // CellRendererMATERIALS: CellRendererMATERIALS,
          // CellRendererCoupon: CellRendererCoupon,
          // CellRendererDropdown: CellRendererDropdown,
          // CellRendererAction: CellRendererAction
        },
        rowHeight: 60,
        columnDefs: [
          {
            maxWidth: 200,
            checkboxSelection: true,
            headerCheckboxSelection: true,
          },
          {
            headerName: "NAME",
            field: "username",
            filter: true,
          },
          {
            headerName: "PERIOD",
            field: "start_date",
            filter: true,
          },
          {
            headerName: "BUDGET",
            field: "budget",
            filter: false,
            valueGetter(params) {
              return `${params.data.budget} ${params.data.budget_currency}`;
            },
          },
          {
            headerName: "TARGET",
            field: "target",
            filter: false,
            valueGetter(params) {
              return `${params.data.target} ${params.data.target_currency}`;
            },
          },
          {
            headerName: "ACTUAL COST",
            field: "actual_cost",
            filter: false,
            valueGetter(params) {
              return `${params.data.actual_cost} ${params.data.actual_cost_currency}`;
            },
          },
          {
            headerName: "COMMISSION",
            field: "commission",
            filter: false,
            valueGetter(params) {
              return `${params.data.commission} ${params.data.commission_currency}`;
            },
          },
          {
            headerName: "PROFIT",
            field: "profit",
            filter: false,
            valueGetter(params) {
              return `${params.data.profit} ${params.data.profit_currency}`;
            },
          },
          {
            headerName: "STATUS",
            Width: 100,
            cellRendererFramework: "CellRendererDropdown",
            suppressSorting: true,
          },
          {
            headerName: "ACTION",
            Width: 80,
            cellRendererFramework: "CellRendererAction",
            // cellRenderer:(params)=>{
            //     return `<button  class="fa fa-arrow-alt-circle-right"></button>`
            // },

            suppressSorting: false,
            selectable: false,
          },
          // {
          //   maxWidth: 50,
          //   class: "grid-checkbox",
          //   checkboxSelection: true,
          //   headerCheckboxSelection: true,
          // },
          // {
          //   headerName: "DATE",
          //   field: "date",
          //   minWidth: 120,
          //   filter: true,
          // },
          // {
          //   headerName: "TYPE",
          //   field: "type",
          //   filter: true,
          // },
          // {
          //   headerName: "COST",
          //   field: "cost",
          //   filter: false,
          // },
          // {
          //   headerName: "EXPECTED ORDERS",
          //   field: "expected_order",
          //   filter: false,
          // },
          // {
          //   headerName: "COMMISSION",
          //   field: "commission",
          //   filter: false,
          // },
          // {
          //   headerName: "PROFIT",
          //   field: "profit",
          //   filter: false,
          // },
          // {
          //   headerName: "ROI",
          //   field: "roi",
          //   filter: false,
          // },
          // {
          //   headerName: "COUPON",
          //   field: "coupon",
          //   filter: false,
          // },
          // {
          //   headerName: "COMMENT",
          //   Width: 80,
          //   cellRendererFramework: "CellRendererComment",
          //   // cellRenderer:(params)=>{
          //   //     return `<button class="fa fa-comment-dots"></button>`
          //   // },
          //   suppressSorting: true,
          // },
          // {
          //   headerName: "STATUS",
          //   width: 80,
          //   cellRendererFramework: "CellRendererDropdown",
          //   suppressSorting: true,
          // },
        ],
      },
      brief_arr: [],
      period_arr: {},
      // period_arr: [
      //   {
      //     id: 0,
      //     date: "04-10-2020",
      //     type: "Mega influencer",
      //     cost: "AED 1,960",
      //     expected_order: "12,0000",
      //     commission: "AED 8,960",
      //     profit: "AED 8,960",
      //     roi: "21",
      //     coupon: "AE471",
      //     comment: "comment",
      //     status: "active",
      //   },
      //   {
      //     id: 0,
      //     date: "04-10-2020",
      //     type: "Photo Ads",
      //     cost: "AED 1,960",
      //     expected_order: "12,0000",
      //     commission: "AED 8,960",
      //     profit: "AED 8,960",
      //     roi: "21",
      //     coupon: "AE471",
      //     comment: "comment",
      //     status: "active",
      //   },
      // ],
    };
  },
  computed: {
    edit_row: {
      get() {
        return this.editable_row;
      },
      set() {
        return this.editable_row;
      },
    },
    view_plan: {
      get() {
        return this.period_arr;
      },
      set() {
        return this.period_arr;
      },
    },
  },
  beforeMount() {
    this.context = {
      parent: this,
      viewable: true,
      editable: true,
      deleteable: false,
    };
  },
  mounted() {
    this.fetchCities();
    console.log(this.campData, "prop-value");
    // let rows =  this.$store.getters.CampaignGetters;
    // console.log('row',rows[0])
    // if(rows.length>0){
    //   this.onSelectCampaign(rows[0]);
    // }
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    this.$store.dispatch("INF_CAMPAIGN_ACTION", []);
    // this.autoSizeAll();
    this.sizeToFit();
    // this.autoSizeAll();
  },
  updated() {
    // console.log("Update Cycle of Campaigns")
  },
  created() {
    this.getRowHeight = (params) => {
      if (params.node.level === 0) {
        return 80;
      }
      if (params.node.level === 1) {
        return 60;
      }
      return 40;
    };
  },
  methods: {
    show() {
      this.$modal.show("edit-modal");
    },
    hide() {
      this.$modal.hide("edit-modal");
    },
    fetchCities() {
      ApiService.get(`/countries/all-city`).then((response) => {
        console.log(response, "welcome");
        this.cities = response.data.data;
        console.log("this.cities", this.cities);
      });
      console.log("Cities");
    },
    async deleteAllConfirmation() {
      let payload = {
        client_id: this.$route.params.id,
        camp_ids: this.selectedCampaign.map((x) => x._id),
      };
      await ApiService.post("/campaigns/delete", payload);
      this.selectedCampaign = [];
      this.fetchData();
    },
    toggleReqOption() {
      this.showReq = !this.showReq;
    },
    autoSizeAll(skipHeader) {
      var allColumnIds = [];
      this.gridColumnApi.getAllColumns().forEach(function(column) {
        allColumnIds.push(column.colId);
      });

      this.gridOptions.columnApi.autoSizeColumns(allColumnIds, false);
    },
    sizeToFit() {
      console.log(this.gridOptions);
      // this.gridColumnApi.skipHeaderOnAutoSize();
    },
    autoSizeAll() {
      var allColumnIds = [];
      this.gridColumnApi.getAllColumns().forEach(function(column) {
        allColumnIds.push(column.colId);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds, false);
    },
    changeSelection() {
      this.$store.dispatch("INF_CAMPAIGN_ACTION", this.selectedCampaign);
    },
    async filterData(event) {
      let filterData = [];
      for (var i = 0; i < this.campaignData.length; i++) {

        if (
          event === this.campaignData[i].start.substring(0, 7) ||
          event === this.campaignData[i].end.substring(0, 7)
        ) {
          let res = await ApiService.post("/ads/media-plan", {
            campaign_id: this.campaignData[i]._id,
          });
          this.campaignData[i].media_plan = res.data.data.data;

          filterData.push(this.campaignData[i]);
        }
      }
      this.$emit("counts", filterData);
      this.campaigns = filterData;
    },
    async fetchData() {
      console.log(this.$route.params.id);
      let dataToPost = {
        client_id: this.$route.params.id,
        page: this.currentPage,
        per_page: this.perPage,
      };
      let response = await ApiService.post(
        "campaigns/all-influencer",
        dataToPost
      );
      // this.campaigns = response.data.data.data;
      this.campaignData = response.data.data.data;
      this.rows = response.data.data.total;
      // if(this.$route.query)
      if (this.$route.query.month) {
        this.filterData(this.$route.query.month);
        this.currentdDate = this.$route.query.month;
      } else {
        this.filterData(this.currentdDate);
      }
      // console.log(this.campaigns.data);
    },
    addRequirement() {
      this.requirement.push({
        name: "",
      });
    },
    closeInput(index) {
      this.requirement.splice(index, 1);
    },
    addInfField() {
      this.influencer.push({
        plateform: "",
        views: "",
        location: "",
      });
    },
    handleSubmit() {
      let dataToPost = {
        client_id: this.$route.params.id,
        campaign_id: this.brief_arr._id,
        description: this.brief_arr.description,
        requirements: this.requirement.map((x) => x.name),
        influencer_details: this.influencer,
      };
      console.log("edit Campaign", dataToPost);
      this.isLoading = true;
      ApiService.post("/campaigns/update", dataToPost).then((response) => {
        console.log("response ", response.data.data.message);
        this.isLoading = false;
        location.reload();
        this.$bvModal.hide("view_brief");
        this.$emit("refresh", response.data.data.message);
      });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.fetchData();
    },
    onSelectCampaign(campaign) {
      this.selectedCampaignVal = this.gridApi.getSelectedRows();
      this.$emit("clicked", this.gridApi.getSelectedRows());
      console.log(this.gridApi.getSelectedRows());
      // console.log("caalling ", campaign)
      if (!campaign.node.selected) {
        let index = this.selectedCampaign.indexOf(campaign.data);
        this.selectedCampaign.splice(index, 1);
        return;
      }
      // this.campaigns.find((x) => {
      //   if (x._id === campaign.data._id) {
      //     this.selectedCampaign.push(campaign.data);
      //     return;
      //   }
      // });
      this.selectedCampaign = this.gridApi.getSelectedRows();
    },
    viewGridAction(data) {
      this.period_arr = data;
      console.log("view plan ", data, this.view_plan);
      this.$bvModal.show("view_plan");
    },
    viewBriefAction(data) {
      this.brief_arr = data;
      this.$bvModal.show("view_brief");
      this.requirement = this.brief_arr.requirements.map((x) => {
        return {
          name: x,
        };
      });
      this.influencer = this.brief_arr.influencer_details;
      // console.log("brief", this.influencer);
    },
    editGridAction(data) {
      this.show();
      // console.log("Edit ",data);
      this.editable_row = data;
      if (this.$route.fullPath.startsWith("/influencer")) {
        this.$bvModal.show("edit-campaign-modal");
      } else {
        this.$bvModal.show("edit-campaign-modal");
      }
      this.fetchData();
    },
    deleteGridAction(data) {
      let dataToPost = {
        client_id: this.$route.params.id,
      };
      console.log("Delete", data);
    },
    onSelectperiod() {},
  },
};
</script>
<style>
.ag-cell-focus,
.ag-cell-no-focus {
  border: none !important;
}
.ag-theme-material .ag-cell {
  border: 1px solid transparent;
  line-height: unset;
  display: flex;
  align-items: center;
}
</style>
<style lang="scss" scoped>
* {
  font-family: Poppins;
}

.infno {
  color: black;
  font-weight: bold;
  margin-right: 14px;
}

.brief_title {
  font-size: 20px;
  font-weight: bold;
  color: black;
}
.select-image {
  width: 32px;
}
.flag-image {
  width: 20px;
  height: 15px;
}
.brief_date {
  font-size: 15px;
  font-weight: 600;
  color: #f76d67;
}
.input_description {
  font-weight: bold;
  font-size: 14px;
  background-color: #f3f6f9;
  height: 10rem;
}
.description_label .label {
  font-weight: bold !important;
}

.add-btn {
  float: right;
  width: 15rem;
  height: 3.5rem;
  background-color: #1374d6;
  border-radius: 10px;
  font-size: 1.3rem;
  color: #ffffff;
}
.label_weight {
  font-size: 14px;
  font-weight: 500 !important;
  letter-spacing: 0.02rem;
  color: black;
}
.text {
  font-size: 14px;
  font-weight: 500;
}
.custom-input {
  height: 45px;
  background-color: #f3f6f9;
}
.InfDetPlat {
  border-right: 0.2rem solid #d7dadc;
  border-radius: 0.5rem 0rem 0rem 0.5rem;
  /* padding: inherit !important; */
  display: inline-flex !important;
  padding: 0em 0.3em;
  justify-content: center;
  align-content: center;
  overflow: auto;
  background-color: #f3f6f9;
  height: 4rem;
  width: fit-content;
}
.InfDetViews {
  border-right: 0.2rem solid #d7dadc;
  border-radius: 0.4rem 0rem 0rem 0.4rem;
  /* padding: inherit !important; */
  display: inline-flex !important;
  padding: 0.4em 0.3em;
  justify-content: center;
  align-content: center;
  overflow: auto;
  background-color: #f3f6f9;
  height: 4rem;
  width: fit-content;
}
.InfDetFlag {
  border-radius: 0rem 0.5rem 0.5rem 0rem;
  /* padding: inherit !important; */
  display: inline-flex !important;
  padding: 0.4em 0.3em;
  justify-content: center;
  align-content: center;
  overflow: auto;
  background-color: #f3f6f9;
  height: 4rem;
  width: fit-content;
}
.infImg {
  height: 1.5rem;
  width: 1.8rem;
  padding: auto;
  margin: auto 0.3rem;
}
.InfCityTxt {
  margin: auto 0px;
  font-weight: bolder;
  font-size: 1rem;
}
.ViewsTxt {
  height: 0.8rem;
  margin: auto 0px;
  /* padding: auto; */
  font-weight: bold;
  font-size: 1rem;
}
.modal-dialog {
  width: 90% !important;
  max-width: 560px;
}
.deleteAll-btn {
  background: #1c97c3 !important;
  float: right !important;
  color: white !important;
  width: 120px !important;
  text-transform: uppercase;
}
::v-deep .deleteAllModal {
  width: 50% !important;
}

::v-deep .view-media-plan-modal {
  width: 90% !important;
}
.mdi-plus-bg2,
.close {
  font-size: 14px !important;
}
.socialText,
#input-221,
input {
  font-size: 12px !important;
  height: 10px;
}

::v-deep .v-select__slot,
::v-deep .v-text-field__slot {
  font-size: 12px;
}
::v-deep .countary {
  display: none;
}
</style>
