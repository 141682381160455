<template>
  <div class="mx-0 px-0 metrics">
    <span class="metricIcons ">
      <v-icon large color="">
        mdi-eye
      </v-icon>
      <span class="text_count ml-1"> {{ this.params.data.view }}</span>
    </span>
    <span class="metricIcons">
      <v-icon large color="">
        mdi-cards-heart
      </v-icon>
      <span class="text_count ml-1">{{ this.params.data.like }}</span>
    </span>
  </div>
</template>

<script>
export default {
  mounted() {
    // console.log(this.params.data.like, "zzzzzzzzzzzzzzzzzz");
  },
};
</script>

<style scoped>
/* .metricIcons {
  color: red;
} */
</style>
