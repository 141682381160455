<template>
  <div>
    <div class="d-flex justify-content-between">
      <div class="header_items" style="width: 55%">
        <h2 class="mb-0">{{ campaignData.campaign_title }}</h2>
        <p class="py-0 my-0 client">{{ campaignData.client_name }}</p>
        <!-- <p class="modal-date">01 October 2020</p> -->
      </div>
      <div class="header_items ml-12 mt-0 mr-6">
        <div class="header_data text-center">
          <span class="header_data__span">Campaign Cost</span>
          <p class="header_data__p">
            {{ campaignData.campaign_cost_currency }}
            {{ campaignData.campaign_cost }}
          </p>
        </div>
        <div class="header_data text-center">
          <span class="header_data__span">Influencers</span>
          <p class="header_data__p">{{ campaignData.influencers }}</p>
        </div>

        <div
          class="close-btn"
          role="button"
          @click="$bvModal.hide('view_plan')"
        >
          <span>Close</span>
        </div>
      </div>
    </div>
    <ag-grid-vue
      class="ag-grid-table ag-theme-material table table-responsive"
      :gridOptions="gridOptions"
      @grid-ready="onGridReady"
      :suppressDragLeaveHidesColumns="true"
      :defaultColDef="defaultColDef"
      :rowData="allocation"
      :animateRows="true"
      rowSelection="multiple"
      @rowSelected="onSelectAds"
    >
    </ag-grid-vue>
    <b-row class="justify-content-end">
      <b-btn class="mx-3" @click="reject_selected">REJECT SELECTED</b-btn>
      <b-btn
        variant="primary"
        :disabled="!selectedAds.length"
        @click="confirmAdsStatus"
        ><b-spinner v-if="isLoading" /> CONFIRM SELECTED</b-btn
      >
    </b-row>

    <b-modal id="reject_selected" :hide-footer="true">
      <div class="header_items">
        <h2 class="mb-0">Reject Khalid Ali</h2>
        <p class="py-0 my-0 client">Ad on October 4, 2020</p>
      </div>
      <div class="mt-10">
        <p class="header_data__p">Reason</p>
        <div>
          <b-form-checkbox-group
            @change="sss"
            size="lg"
            v-model="Reasons"
            :options="options"
            :text-field="options.text"
            stacked
          ></b-form-checkbox-group>

          <!-- <b-form-input
            class="my-6"
            id="input-small"
            size="sm"
            placeholder="Other"
          ></b-form-input> -->
        </div>
      </div>
      <v-text-field
        v-model="other_reason"
        class="my-6 Reason_txt"
        label="Other Reason"
      ></v-text-field>
      <b-row class="justify-content-end">
        <b-btn class="mx-3" @click="Close_Reject">Cancel</b-btn>
        <b-btn variant="primary" @click="Confirm_selected">CONFIRM</b-btn>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue';
import CellRendererDropdown from '@/view/pages/CellRenderDropdown.vue';
import CellRenderProfile from '@/view/components/CellRenderProfile';
import ApiService from '@/core/services/api.service';

import CellRendererAction from '@/view/pages/CellRenderAction.vue';
export default {
  name: 'ads',
  props: ['campaign'],
  components: {
    AgGridVue,
    CellRendererDropdown,
    CellRendererAction,
    CellRenderProfile,
  },
  data() {
    return {
      selectedAds: [],
      isLoading: false,
      Reasons: [],
      other_reason: '',
      options: [
        { text: 'Too Expensive', value: 'Too Expensive' },
        { text: 'Not The Right Candidate', value: 'Not The Right Candidate' },
        { text: 'Wrong Target audience', value: 'Wrong Target audience' },
      ],
      searchQuery: '',
      maxPageNumbers: 7,
      gridApi: null,
      StartingPlan: false,
      defaultColDef: {
        sortable: true,
        resizable: true,
        // suppressMenu: true,
        flex: 1,
        filter: true,
        resizable: true,
        minWidth: 130,
      },
      gridOptions: {
        frameworkComponents: {
          //   CellRendererAvatars: CellRendererAvatars,
          //   CellRendererName: CellRendererName,
          //   CellRendererMATERIALS: CellRendererMATERIALS,
          //   CellRendererCoupon: CellRendererCoupon,
          //   CellRendererDropdown: CellRendererDropdown,
          //   CellRendererAction: CellRendererAction
        },
        context: {
          componentParent: this,
        },
        rowHeight: 60,
        // rowWidth: 100,
        columnDefs: [
          {
            maxWidth: 40,
            checkboxSelection: true,
            headerCheckboxSelection: true,
          },
          {
            headerName: 'DATE',
            field: '',
            filter: true,
            cellStyle: { 'line-height': '2' },
            cellRenderer: (params) => {
              return `<span  class="">${params.data.planned_date}</span><br/>
              <span class="status-col">${params.data.status}</span>
              `;
            },
            // cellRendererParams:{
            //     action: doSomeAction.bind(this),

            // },
          },
          {
            headerName: 'INFLUENCER',
            cellRendererFramework: 'CellRenderProfile',
            suppressSorting: true,
            minWidth: 190,
          },
          {
            headerName: 'AUDIENCE',
            minWidth: 150,
            filter: false,
            cellStyle: { 'line-height': '4' },
            cellRenderer: (params) => {
              return `<span  class="users">${
                params.data.audience ? params.data.audience : 0
              }
              `;
            },
          },
          {
            headerName: 'LOCATION',
            field: 'city',
            filter: false,
          },
          {
            headerName: 'AD TYPE',
            field: 'type',
            filter: false,
          },
          {
            headerName: 'COST',
            field: '',
            filter: false,
            cellRenderer: (params) => {
              return `${params.data.cost_currency} ${params.data.cost}`;
            },
          },

          {
            headerName: 'COMMENT',
            Width: 80,
            class: 'text-center',
            style: 'text-align:center',
            // cellRendererFramework: 'CellRendererAction',
            cellRenderer: (params) => {
              return `<button  class="fa fa-comment-dots"></button>`;
            },
            // cellRendererParams:{
            //     action: doSomeAction.bind(this),

            // },
            suppressSorting: true,
          },
          {
            headerName: 'STATUS',
            Width: 80,
            cellRendererFramework: 'CellRendererDropdown',
            suppressSorting: true,
          },
        ],
      },
      allocation: [],
      campaignData: {
        campaign_cost: 0,
        campaign_cost_currency: 'AED',
        campaign_title: '',
        client_name: '',
        influencers: 0,
      },
    };
  },
  async mounted() {
    // this.sizeToFit();
    // this.gridApi = this.gridOptions.api;
    this.getMediaPlans();
  },
  methods: {
    sss() {
      console.log(this.Reasons + '' + this.other_reason);
    },
    Confirm_selected() {
      this.$bvModal.hide('view_plan');
    },
    reject_selected() {
      this.$bvModal.show('reject_selected');
    },
    Close_Reject() {
      this.$bvModal.hide('reject_selected');
    },
    Confirm_Reasons() {
      this.$bvModal.hide('reject_selected');
    },

    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },
    abc() {
      console.log('heelo ADS');
    },
    onGridReady(params) {
      console.log(params);
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    onSelectAds(ads) {
      this.selectedAds = this.gridApi.getSelectedRows();
    },
    async getMediaPlans() {
      let res = await ApiService.post('/ads/media-plan', {
        campaign_id: this.campaign._id,
      });
      console.log('campaign_id', res.data.data.data);
      //  this.campaign_id = res.data.data;
      this.allocation = res.data.data.data;
      this.campaignData = res.data.data;
    },

    async confirmAdsStatus() {
      const APIURL = '/ads/status';
      let payload = this.selectedAds.map((x) => {
        return {
          ad_id: x._id,
          status: 'Confirm',
        };
      });
      try {
        this.isLoading = true;
        let response = await ApiService.post(APIURL, payload);
        this.selectedAds = [];
        console.log('response ', response.data.message);
        this.checkAdsStatus();
      } catch (err) {
      } finally {
        this.isLoading = false;
      }
    },

    async checkAdsStatus() {
      await this.getMediaPlans();
      let isAllAdsConfirm = this.allocation.every(
        (x) => x.status.toLowerCase() == 'confirm'
      );

      // if all ads are confirm then we have to update campaign status to Approved.
      if (isAllAdsConfirm) {
        await this.updateCampaignStatus();
        this.Confirm_selected();
        this.$emit('fetch');
      }
    },

    async updateCampaignStatus() {
      const APIURL = '/campaigns/status';

      let payload = {
        client_id: this.$route.params.id,
        campaign_id: this.campaign._id,
        status: 'Approved',
      };
      let res = await ApiService.post(APIURL, payload);
      console.log('response ', res.data.message);
    },
  },
};
</script>

<style scss>
* {
  font-family: Poppins;
}
h2 {
  font-weight: bold;
  color: black;
}
.my-bad {
  color: blue !important;
  background: lightskyblue;
  padding: 8px 16px;
  border-radius: 13%;
  font-weight: 600;
}
.client {
  color: gray;
  font-size: 14px;
  font-weight: 700;
}
.modal-date {
  font-size: 1.2rem !important;
  font-weight: 500 !important;
}
.header_items {
  display: inline-block;
}
.close-btn {
  position: absolute;
  top: 5px;
  right: 10px;
  font-weight: 600;
  cursor: pointer;
}
.header_data {
  display: inline-block;
  margin: 0 6px;
  background-color: rgb(241, 241, 241);
  padding: 5px 8px;
  border-radius: 10px;
  width: 130px;
  margin-right: 15px;
}

.header_data__p {
  font-weight: bold;
  font-size: 1.2rem;
  color: black;
}
.header_data__span {
  color: black;
  font-weight: 600;
}
.Reason_txt {
  width: 60%;
}
.custom-checkbox.b-custom-control-lg,
.input-group-lg .custom-checkbox {
  margin-bottom: 2rem;
  margin-left: 0.5rem;
}
.status-col {
  background: #d2d2d269 0% 0% no-repeat padding-box;
  border-radius: 7px;
  padding: 0px 5px 0px 5px;
  font: normal normal bold 12px/18px Poppins;
  letter-spacing: 0px;
  color: #000000;
}
.age-aud {
  border-radius: 7px;
  padding: 0px 5px 0px 5px;
  font: normal normal bold 12px/18px Poppins;
  letter-spacing: 0px;
  color: #000000;
}
.users {
  text-align: left;
  font: normal normal medium 18px/27px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.6;
}
</style>
