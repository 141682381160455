<template>
  <div class="edit-campaign">
    <b-row>
      <b-col>
        <span class="main-heading">Edit Campaign</span>
        <br />
        <span class="sub-heading">for {{ ClientName }}</span>
      </b-col>
      <b-col cols="auto" role="button" @click="hide()" class="close">
        close
      </b-col>
    </b-row>

    <form ref="form" class="form-data">
      <!-- ADD DATE -->
      <b-form-group id="fieldset" label="Add Date" label-for="add-date">
        <b-row id="add-date">
          <b-col class="pb-0 pt-0">
            <b-form-group
              id="fieldset-start-date"
              label-cols-sm="4"
              label-cols-lg="2"
              content-cols-sm
              content-cols-lg="10"
              label="from"
              label-for="start-date"
            >
              <b-form-datepicker
                id="start-date"
                v-model="startDate"
                @context="onSelectStartDate"
                :disabled="isFullMonthCampaign"
                placeholder="1/10/2020"
                locale="en"
                value="row.start"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>

          <b-col class="pb-0 pt-0">
            <b-form-group
              id="fieldset-end-date"
              label-cols-sm="4"
              label-cols-lg="2"
              content-cols-sm
              content-cols-lg="10"
              label="to"
              label-for="end-date"
            >
              <b-form-datepicker
                id="end-date"
                v-model="endDate"
                :disabled="isFullMonthCampaign"
                @context="onSelectEndDate"
                placeholder="31/10/2020"
                locale="en"
                :min="min"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="startDate">
          <b-col class="pt-0">
            <b-form-checkbox
              class="custom-control custom-checkbox checkbox-xl"
              v-model="isFullMonthCampaign"
              @change="toggleFullMonth"
            >
              <span class="labels campaign-label">
                FULL MONTH CAMPAIGN
                <span style="color: #f76d67">(31 DAYS)</span>
              </span>
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-form-group>

      <!-- ADD NAME -->
      <b-form-group
        id="name-fieldset"
        class="input-label"
        label="Campaign Name"
        label-for="compaignName"
      >
        <b-form-input
          id="compaignName"
          v-model="form.title"
          value="usadmma"
          class="input-field custom-input"
          type="text"
          required
          placeholder="Add name here"
        />
      </b-form-group>

      <b-row class="position-relative align-items-baseline">
        <!-- ADD BUDGET -->
        <b-col class="col-6 col-md-4">
          <b-form-group
            id="budget-fieldset"
            class="input-label mt-5 w-100 px-0"
            label="Add Budget"
            label-for="budget"
          >
            <b-input-group type="number" class="mt-3">
              <template #prepend>
                <b-dropdown :text="budget_currency" class="currency-dropdown">
                  <b-dropdown-item @click="budget_currency = 'AED'"
                    >AED</b-dropdown-item
                  >
                  <b-dropdown-item @click="budget_currency = 'USD'"
                    >USD</b-dropdown-item
                  >
                  <b-dropdown-item @click="budget_currency = 'SAR'"
                    >SAR</b-dropdown-item
                  >
                  <b-dropdown-item @click="budget_currency = 'KWD'"
                    >KWD</b-dropdown-item
                  >
                </b-dropdown>
              </template>

              <b-form-input
                @input="profit"
                type="number"
                v-model="budget"
              ></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col class="col-6 col-md-4">
          <!-- ADD BILLABLE AMOUNT -->
          <b-form-group
            id="budget-fieldset"
            class="input-label px-0"
            label="Add Billable Amount"
            label-for="billableAmount"
          >
            <b-input-group type="number" class="mt-3">
              <template #prepend>
                <b-dropdown :text="budget_currency" class="currency-dropdown">
                  <b-dropdown-item @click="budget_currency = 'AED'"
                    >AED</b-dropdown-item
                  >
                  <b-dropdown-item @click="budget_currency = 'USD'"
                    >USD</b-dropdown-item
                  >
                  <b-dropdown-item @click="budget_currency = 'SAR'"
                    >SAR</b-dropdown-item
                  >
                  <b-dropdown-item @click="budget_currency = 'KWD'"
                    >KWD</b-dropdown-item
                  >
                </b-dropdown>
              </template>

              <b-form-input
                @input="profit"
                type="number"
                v-model="billbleAmount"
              ></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col class="col-6 col-md-4">
          <label class="d-block">Expected Profit</label>
          <div class="profit-text">
            {{ expected_profit_currency }} {{ expected_profit }}
          </div>
        </b-col>
        <span class="devide-line"></span>
      </b-row>

      <!-- DESCREAPTION -->
      <div class="mt-8">
        <div class="brief-text">Add Brief</div>
        <b-form-group
          class="input-label"
          label="Description"
          label-for="compaignBrief"
        >
          <b-form-textarea
            v-model="description"
            type="text"
            required
            placeholder="Add Description"
          />
        </b-form-group>
      </div>

      <!-- ADD REQUIREMENT -->
      <b-form-group class="input-label mt-5 mb-0 col-12 pl-0">
        <label
          >Requirements
          <v-btn
            @click="toggleRequirementOption"
            class="mx-1 ml-4 mr-4 mdi-plus-bg2"
            fab
            dark
            color="blue"
          >
            <v-icon class="mdi-plus-content2">mdi-plus</v-icon>
          </v-btn>
        </label>
        <b-row>
          <b-col
            class="pb-0 col-12"
            v-for="(item, index) in requirement"
            :key="index"
          >
            <b-form-group class="input-label">
              <b-input-group>
                <b-form-input
                  id="roi"
                  class="input-field custom-input"
                  type="text"
                  v-model="item.name"
                />
                <b-input-group-append @click="closeInput(index)">
                  <b-input-group-text>
                    <b-icon icon="x" font-scale="2" />
                  </b-input-group-text>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>
      <!-- ADD REQUIREMENT -->

      <!-- INFUENCER DETAIL -->
      <b-form-group class="input-label col-12 pb-12 position-relative">
        <span class="devide-line-2"></span>
        <label class="inf-detail-label"
          >Influencer Details
          <v-btn
            @click="addInfuencerFields"
            class="mx-1 ml-4 mdi-plus-bg2"
            fab
            dark
            color="blue"
          >
            <v-icon class="mdi-plus-content2">mdi-plus</v-icon>
          </v-btn>
        </label>
        <b-row
          class="influencer position-relative mt-3"
          v-for="(field, index) in infuencerFields"
          :key="index"
        >
          <span class="inf-field-remove-btn">
            <v-btn icon color="dark" @click="removeINF">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </span>
          <!-- PLATFORMS -->
          <b-col class="col-6 col-md-3 border-right py-7 px-5">
            <b-form-group class="input-label mb-0">
              <label class="sub-label">Platform </label>
              <v-select
                color="#00a4f3"
                background-color="#f3fcff"
                outlined
                dense
                height="45"
                hide-details="true"
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="field.platform"
                :items="itemss"
                placeholder="Select"
                :persistent-hint="false"
              >
                <template v-slot:selection="{ item }">
                  <div>
                    <img :src="item.image" class="select-image" />
                  </div>
                </template>
                <template v-slot:item="{ item }">
                  <img :src="item.image" class="select-image" />
                  <span class="socialText"> {{ item.name }}</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <!-- PLATFORMS -->
          <!-- INFLUENSERS -->
          <b-col class="col-6 col-md-3 border-right py-7 px-5">
            <b-form-group class="input-label mb-0">
              <label class="sub-label">No. of Influencers </label>
              <b-form-input
                id="roi"
                v-model="field.no_of_influencers"
                class="input-field custom-input mb-0"
                type="number"
                placeholder="add here"
              />
            </b-form-group>
          </b-col>
          <!-- INFLUENSERS -->
          <!-- VIEWS -->
          <b-col class="col-6 col-md-3 border-right py-7 px-5">
            <label class="sub-label">Views </label>
            <b-form-group class="input-label mb-0">
              <b-form-input
                id="orders"
                v-model="field.views"
                class="input-field custom-input"
                type="number"
                placeholder="add here"
              />
            </b-form-group>
          </b-col>
          <!-- VIEWS -->
          <!-- LOCATION -->
          <b-col class="col-6 col-md-3 py-7 px-3">
            <b-form-group class="input-label mb-0">
              <label class="sub-label">Location </label>
              <v-autocomplete
                color="#00a4f3"
                background-color="#f3fcff"
                outlined
                dense
                height="45"
                hide-details="true"
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="field.location"
                placeholder="Select"
                :items="cities"
                :persistent-hint="false"
              >
                <!-- <template v-slot:selection="{ item }">
                    <div v-bind:class="{ loctaionText: item.length > 7 }">
                      {{ item }}
                    </div>
                  </template>
                  <template v-slot:item="{ item }">{{ item }} </template> -->
              </v-autocomplete>
            </b-form-group>
          </b-col>
          <!-- LOCATION -->
        </b-row>
      </b-form-group>
      <!-- INFUENCER DETAIL -->

      <!-- ADD OFFERS -->
      <b-form-group class="input-label mt-5 col-12 mb-0 pb-0 pl-0">
        <label
          >Add Offers
          <v-btn
            @click="toggleOffersOption"
            class="mx-1 ml-4 mr-4 mdi-plus-bg2"
            fab
            dark
            color="blue"
          >
            <v-icon class="mdi-plus-content2">mdi-plus</v-icon>
          </v-btn>
        </label>
      </b-form-group>
      <v-row v-if="showOffersSection">
        <v-col cols="12">
          <multiselect
            v-model="value"
            tag-placeholder="Add this as new offer"
            placeholder="Search or add a offer"
            track-by="title"
            :options="options"
            :multiple="true"
            :taggable="true"
            label="title"
            @tag="addTag"
          ></multiselect>
        </v-col>
      </v-row>

      <!-- ADD COUPONS -->
      <b-form-group
        id="name-fieldset"
        class="input-label mt-5"
        label="Add Coupons"
        label-for="coupons"
      >
        <multiselect
          v-model="couponsData"
          tag-placeholder="Add this as new offer"
          placeholder="Search or add a offer"
          track-by="name"
          :options="couponsList"
          :multiple="true"
          :taggable="true"
          label="name"
          @tag="addCouponTag"
        ></multiselect>
      </b-form-group>
      <!-- ADD BUTTON -->
      <b-form-group class="mt-15">
        <b-button class="add-btn" @click="handleSubmit"
          >Save <b-spinner v-if="isLoading" small type="grow"></b-spinner>
        </b-button>
      </b-form-group>
    </form>
  </div>
</template>
<style lang="scss">
.loctaionText {
  white-space: nowrap;
  overflow: hidden;
  margin-top: 36px;
}
.inf-field-remove-btn {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9999;
}
.border-right {
  border-right: 2px solid #ccc !important;
}
.devide-line {
  position: absolute;
  width: calc(100% + 18px);
  height: 1px;
  background-color: #e2e2e2;
  bottom: 0;
  left: -9px;
}
.devide-line-2 {
  position: absolute;
  width: calc(100% + 3.5rem);
  height: 1px;
  background-color: #e2e2e2;
  bottom: 0;
  left: -1.75rem;
}
.brief-text {
  font-size: 20px;
  font-weight: 700;
}

.create-campaign,
.edit-campaign {
  font-family: "Poppins";

  .main-heading {
    font-weight: bold;
    font-size: 24px;
    color: #000000;
    font-family: Poppins;
  }
  .sub-heading {
    font-size: 18px;
    font-weight: 600;
    color: #939393;
  }
  .close {
    font-size: 16px;
    font-weight: 600;
    color: #000000;
    opacity: 0.36;
  }
  .profit-text {
    font-size: 22px;
    font-weight: 600;
    line-height: 2.25;
  }
  .inf-detail-label {
    margin-left: -12px;
  }
  .form-data {
    margin-top: 10px;
    .b-form-datepicker {
      background: #f3fcff 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
      height: 45px !important;
      font-size: 16px;
    }
    select,
    input {
      height: 45px;
      background: #f3fcff 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
      font-size: 16px;
      color: #000000;
    }
    textarea {
      min-height: 200px;
      background: #f3fcff 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
      font-size: 16px;
      color: #000000;
    }
    .remove-btn {
      border: 1px solid #eee;
      background-color: rgba($color: #1374d6, $alpha: 1);
      padding: 5px 8px;
      border-radius: 8px;
      color: #eee;
      transition: all 0.3s ease;
      margin-right: -12px;
      &:hover {
        background-color: rgba($color: #1374d6, $alpha: 0.5);
      }
    }
    #checkbox-1::after {
      width: 25px;
      height: 23px;
      background: #00a4f3 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
    }
    .checkbox-xl .custom-control-label::before,
    .checkbox-xl .custom-control-label::after {
      width: 25px;
      height: 23px;
      border-radius: 8px;
      opacity: 1;
    }
    .checkbox-xl .custom-control-label {
      padding-left: 24px;
      padding-top: 0px;
    }

    .multiselect__content-wrapper {
      ul {
        padding-left: 0;
      }
    }
    .multiselect__tags {
      border: none !important;
      min-height: 45px;
      background: #f3fcff 0% 0% no-repeat padding-box;
      border-radius: 8px;
      font-size: 16px;
      color: #000000;
      border: 1px solid #e4e6ef !important;
    }
    .multiselect__tag {
      background-color: #000;
    }
    .multiselect__tag-icon:hover {
      background: var(--v-accent-base);
    }
    .multiselect__select::before {
      top: 90%;
    }
    .v-text-field .v-input__slot {
      border-radius: 8px;
      border: none !important;
    }
    label {
      align-self: center;
      font-size: 14px !important;
      color: #000000;
    }
    .sub-label {
      font-size: 12px !important;
      font-weight: 600 !important;
    }
    .custom-control-input {
      display: none;
    }
    .campaign-label {
      text-align: left;
      font: normal normal bold 14px/22px Poppins !important;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
    }
    .add-btn {
      float: right;
      width: 150px;
      height: 45px;
      background: #1374d6 0% 0% no-repeat padding-box;
      border-radius: 10px;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 1.6px;
      color: #ffffff;
    }
    .payout-dropdown {
      button,
      button:focus {
        background: black;
        color: #fff;
        font-size: 16px;
        font: normal normal bold 12px/25px Poppins !important;
      }
      button::after {
        font-size: 16px;
        color: #fff;
      }
    }
    .currency-dropdown {
      button,
      button:focus {
        background: #f3fcff;
        color: #000000;
        font-size: 16px;
        font-weight: 500;
      }
      button::after {
        font-size: 10px;
        color: #000000;
      }
    }
    .v-input input,
    .v-input textarea:focus,
    .v-input textarea:active {
      background-color: transparent;
      margin-top: 0.5rem;
    }
    .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed
      .v-select__selections {
      min-height: 45px !important;
      font-size: 14px;
    }
    .currency-dropdown .dropdown-menu {
      padding: 0.2rem;
    }
  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import ApiService from "../../../../core/services/api.service";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import cities from "../../../../view/components/cities.json";

export default {
  name: "edit-campaign",
  props: ["ClientName", "row"],
  components: { Multiselect },
  data: () => ({
    //   form:null,
    couponsList: [],
    couponsData: [],
    startDate: null,
    startCompleteDate: null,
    endCompleteDate: null,
    endDate: null,
    isFullMonthCampaign: false,
    itemss: [
      {
        name: "Snapchat",
        image:
          "https://cdn2.iconfinder.com/data/icons/black-white-social-media/64/social_media_logo_snapchat-1024.png",
      },
      {
        name: "Facebook",
        image:
          "https://cdn2.iconfinder.com/data/icons/black-white-social-media/32/online_social_media_facebook-512.png",
      },
      {
        name: "Twitter",
        image:
          "https://cdn2.iconfinder.com/data/icons/black-white-social-media/32/twitter_online_social_media-512.png",
      },
      {
        name: "Instagram",
        image:
          "https://cdn2.iconfinder.com/data/icons/black-white-social-media/32/instagram_online_social_media_photo-512.png",
      },
      {
        name: "YouTube",
        image:
          "https://cdn2.iconfinder.com/data/icons/black-white-social-media/32/youtube_online_social_media_tube-512.png",
      },
      {
        name: "TikTok",
        image:
          "https://cdn2.iconfinder.com/data/icons/social-media-2421/512/TikTok-512.png",
      },
    ],
    locations: [
      {
        image: "https://cdn.countryflags.com/thumbs/china/flag-square-250.png",
        name: "China",
      },
      {
        image: "https://cdn.countryflags.com/thumbs/india/flag-square-250.png",
        name: "India",
      },
      {
        image: "https://cdn.countryflags.com/thumbs/europe/flag-square-250.png",
        name: "Europe",
      },
      {
        image:
          "https://cdn.countryflags.com/thumbs/united-states-of-america/flag-square-250.png",
        name: "America",
      },
      {
        image: "https://cdn.countryflags.com/thumbs/japan/flag-square-250.png",
        name: "Japan",
      },
    ],
    value: [],
    options: [],
    name: null,
    budget: "",
    budget_currency: "",
    billbleAmount: "",
    billable_amount_currency: "",
    expected_profit: "",
    expected_profit_currency: "",
    description: "",
    requirement: [],
    infuencerFields: [],
    SelectedLocation: [],
    SelectedPlateform: [],
    influencer_details: {
      no_of_influencers: "",
      views: "",
    },
    // target: {
    //   target: "",
    //   revenue: "",
    //   roi: "",
    //   orders: "",
    //   commission: "",
    //   target_currency: "AED",
    //   revenue_currency: "AED",
    //   commission_currency: "AED",
    // },
    payout: {
      payoutType: "CPO",
      amount: "",
      currencyType: "AED",
    },
    coupons: "",
    products: "",
    geos: "",
    couponsArray: [],
    min: null,
    max: null,
    firstDay: null,
    lastDay: null,
    showTarget: false,
    showPayout: false,
    showOffersSection: true,
    showRequirement: false,
    focusOnProducts: false,
    focusOnGeo: false,
    isLoading: false,
    cities: cities,
  }),
  mounted() {
    this.$modal.show("edit-modal");
    this.fetchData();
    // this.fetchCities();
    //   console.log("Edit Campaign", this.row)
    this.form = this.row;
  },
  methods: {
    hide() {
      this.$modal.hide("edit-modal");
    },
    fetchCities() {
      ApiService.get(`/countries/all-city`).then((response) => {
        console.log(response, "welcome");
        this.cities = response.data.data;
        console.log("this.cities", this.cities);
      });
      console.log("Cities");
    },
    fetchData() {
      ApiService.get(`/offers?client_id=${this.$route.params.id}`).then(
        (response) => {
          this.options = response.data.data.data;
        }
      );
      // fetch coupons
      ApiService.post(`/coupons/get-coupon-by-client`, {
        client_id: this.$route.params.id,
      }).then((response) => {
        var couponData = response.data.data;
        for (let i = 0; i < couponData.length; i++) {
          couponData[i].name = couponData[i].code;
        }
        this.couponsList = couponData;
      });
    },
    addTag(newTag) {
      const tag = {
        title: newTag,
      };
      this.options.title.push(tag);
      this.value.push(tag);
    },
    /* event triggered on selecting start date */
    onSelectStartDate(ctx) {
      this.startDate = this.row.start;
      this.endDate = this.row.end;

      this.startCompleteDate = ctx.activeDate;
      this.firstDay = new Date(
        ctx.activeDate.getFullYear(),
        ctx.activeDate.getMonth(),
        1
      );
      this.lastDay = new Date(
        ctx.activeDate.getFullYear(),
        ctx.activeDate.getMonth() + 1,
        0
      );

      // set date min and max range
      this.min = new Date(this.firstDay);
      this.min.setDate(ctx.activeDate.getDate());
      this.max = new Date(this.lastDay);

      // auto fill campaign name
      this.name = this.getAutoCampaignName();
      this.budget = this.row.budget;
      this.budget_currency = this.row.budget_currency;
      this.billbleAmount = this.row.billable_amount;
      this.expected_profit = this.row.exp_profit;
      this.expected_profit_currency = this.row.exp_profit_currency;
      this.description = this.row.description;
      this.requirement = this.row.requirements.map((x) => {
        return {
          name: x,
        };
      });
      this.infuencerFields = this.row.influencer_details;
      this.value = this.row.offers;
      this.couponsData = this.row.coupons;
    },

    /* event triggered on selecting end date */
    onSelectEndDate(ctx) {
      if (!this.endDate || this.isFullMonthCampaign) {
        return;
      }
      this.endCompleteDate = ctx.activeDate;
      // auto fill campaign name
      this.name = this.getAutoCampaignName();
    },

    /* toggle full month campaign  */
    toggleFullMonth(val) {
      // set month start and end date if user select full month campaign check.
      if (val) {
        this.startDate = this.toJSONLocal(this.firstDay);
        this.endDate = this.toJSONLocal(this.lastDay);
      }
      // auto fill campaign name
      this.name = this.getAutoCampaignName();
    },
    toJSONLocal(date) {
      var local = new Date(date);
      local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      return local.toJSON().slice(0, 10);
    },

    profit() {
      this.expected_profit = this.budget - this.billbleAmount;
    },

    toggleRequirementOption() {
      this.requirement.push({
        name: "",
      });
    },
    toggleOffersOption() {
      this.showOffersSection = !this.showOffersSection;
    },
    closeInput(index) {
      this.requirement.splice(index, 1);
    },
    addInfuencerFields() {
      this.infuencerFields.push({
        location: "",
        no_of_influencers: "",
        platform: "",
        views: "",
      });
    },
    removeINF(index) {
      this.infuencerFields.splice(index, 1);
    },
    getCompignDetails(ctx) {
      this.budget = this.row.budget;
    },

    /* get formatted auto campaign name according to dates. */
    getAutoCampaignName() {
      let month = this.startCompleteDate.toLocaleString("default", {
        month: "long",
      });
      let dateRange =
        this.isFullMonthCampaign || !this.endCompleteDate
          ? ""
          : `${this.startCompleteDate.getDate()} - ${this.endCompleteDate.getDate()}`;
      let year = this.startCompleteDate.getFullYear();
      return;
      return `Adidas, ${dateRange} ${month} ${year} Campaign`;
    },

    toggleTargetOption() {
      this.showTarget = !this.showTarget;
    },
    handleSubmit() {
      var offers = [];
      var coupons = [];
      // set coupons
      if (this.couponsData.length !== 0) {
        for (let i = 0; i < this.couponsData.length; i++) {
          coupons.push(this.couponsData[i]._id);
        }
      }
      //set offers
      if (this.value.length !== 0) {
        for (let i = 0; i < this.value.length; i++) {
          offers.push(this.value[i]._id);
        }
      }
      let dataToPost = {
        client_id: this.$route.params.id,
        campaign_id: this.row._id,
        title: this.form.title,
        budget: this.budget,
        budget_currency: this.budget_currency,
        billable_amount: this.billbleAmount,
        exp_profit: this.expected_profit,
        exp_profit_currency: this.expected_profit_currency,
        description: this.description,
        platform_type: "INFLUENCER",
        requirements: this.requirement.map((x) => x.name),
        influencer_details: this.infuencerFields,
        offers: offers,
        coupons: coupons,
        status: "Edited",
      };
      console.log("edit Campaign", dataToPost);
      this.isLoading = true;
      ApiService.post("/campaigns/update", dataToPost).then((response) => {
        console.log("response ", response.data.data.message);
        this.isLoading = false;
        location.reload();
        this.$bvModal.hide("edit-campaign-modal");
        this.$emit("refresh", response.data.data.message);
      });
    },
  },
  computed: {
    ...mapGetters(["CampaignGetters"]),
    selectedCampaign() {
      return this.$store.getters.CampaignGetters;
    },
    form: {
      get() {
        return this.row;
      },
      set(newVal) {
        return newVal;
      },
    },
    isFormValidated() {
      // if (this.form.billable_amount) {
      //   return true;
      // } else {
      //   return false;
      // }
    },
  },
};
</script>
